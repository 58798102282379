.selectAllContainer {
	padding: 0.7vmax;
}

.checklistBody {
	padding: 0.5vmax;
}

.checkbox.checked :global(.slds-checkbox_faux:after) {
	border-color: white !important;
}

.checkbox.checked :global(.slds-checkbox_faux) {
	background-color: var(--orange) !important;
}
.entry {
	margin: 1vh 0;
}

.listBody {
	overflow-y: auto;
	padding: 0.3vmax;
	height: 15vh;
}

.noResult {
	margin-left: auto;
	margin-right: auto;
	width: 20%;
	display: flex;
	align-items: center;
}

.helper {
	font-size: var(--input-label-font-size) !important;
}
span {
	font-size: 12px !important;
}

.label {
	margin-bottom: 1vh;
	display: flex;
	font-size: 11px;
	margin-left: auto;
	font-family: var(--common-font);
}

.required::after {
	color: #d93025;
	content: '*';
	margin-left: 0.1vw;
}

.optional {
	margin-left: 0.2vw;
	color: #8b9095;
}
