.isSelected {
	background: #f2f2f2 0% 0% no-repeat padding-box;
	font-weight: bold;
}

.isSelected.isStatusActive {
	box-shadow: inset 0.25rem 0 0 #14bb02;
}

.isSelected.isStatusEngaged {
	box-shadow: inset 0.25rem 0 0 #cccf19;
}

.isSelected.isStatusSuspended {
	box-shadow: inset 0.25rem 0 0 #d93025;
}

.isSelected.isStatusDeactivated {
	box-shadow: inset 0.25rem 0 0 #8b9095;
}

.statusIndactorActive {
	fill: #14bb02;
}

.statusIndactorEngaged {
	fill: #cccf19;
}

.statusIndactorSuspended {
	fill: #d93025;
}

.statusIndactorDeactivated {
	fill: #8b9095;
}

.toolTip > div {
	display: flex;
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.toolTip > div > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.cardContainer {
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid #e9e9e9;
	cursor: pointer;
}

.cardContainer:hover {
	background: #f2f2f2 0% 0% no-repeat padding-box;
}

.name {
	inline-size: 10vw;
	overflow-wrap: break-word;
}
