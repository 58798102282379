.paperContainer {
	padding: 1vmax;
	margin-top: 41px;
	border-radius: 10px;
	height: 90%;
}

.title {
	font: normal 600 24px/24px var(--alt-font);
	letter-spacing: 0.74px;
	height: 6.5vh;
}

.mainContainer {
	margin-top: 13px;
}
