#toolTip > div[role='tooltip'] {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
	margin-left: -5.8vw !important;
}

.crossIcon,
.crossIcon:focus {
	color: var(--orange);
}

.crossIcon:hover {
	color: var(--darkerOrange);
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}

.actionCell button {
	width: min(2.5vmax, 30px);
	height: min(1vmax, 20px);
}

.toolTip {
	display: block !important;
	line-height: normal !important;
}

.toolTip > div[role='tooltip'] {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
	margin-left: -5.8vw !important;
}

.toolTip > div[role='tooltip'] > * {
	color: #3b3b3b !important;
}
