.input {
	min-height: var(--input-min-height);
	height: 100%;
	font-family: var(--common-font-light);
	font-size: var(--input-label-font-size);
}

.input:disabled {
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #dadce0;
}

.icon {
	size: 50px !important;
}
.errorInputIcon {
	border: 2px solid #ea001e;
}

.optional {
	color: #8b9095;
	font-style: italic;
}
