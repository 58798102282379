.input :global(.slds-input) {
	padding: 0.3vmax;
	padding-left: 1vw;
	font-size: var(--input-label-font-size);
	min-height: 0;
	line-height: 3vh;

	position: relative;
	width: 100%;
	overflow: hidden;
}

/* .input :global(.slds-input[disabled]) {
	background-color: white;
} */

.input :global(.slds-input:read-only) {
	border-color: var(--sds-c-input-color-border, #dddbda) !important;
}

.input.multiLine {
	border: 1px solid var(--sds-c-input-color-border, #dddbda);
	border-radius: var(--sds-c-input-radius-border, 0.25rem);
	width: 100%;
	height: 100%;
	padding: 0.3vmax;
	padding-left: 1vw;
	line-height: 3vh;
	font-size: var(--input-label-font-size);
	border-color: var(--sds-c-input-color-border, #dddbda);
	background-color: #fff;
}

.input :global(.slds-form-element__help) {
	font-size: var(--input-label-font-size);
}

.fullWidth {
	width: 100%;
}

[data-placeholder]:empty {
	display: flex;
	align-items: center;
	cursor: text;
}

[data-placeholder]:empty:before {
	content: attr(data-placeholder);
	color: #73716f;
}

.input.multiLine.error {
	border: 2px solid #ea001e;
}

.input.multiLine:focus {
	box-shadow: var(--sds-c-input-shadow-focus, 0 0 3px #0176d3);
}

.input.multiLine.error:focus {
	box-shadow: #ea001e 0 0 0 1px inset, 0 0 3px #0176d3;
}

.helper {
	font-size: var(--input-label-font-size);
}

.formGroup {
	position: relative;
	width: 50%; /* <-- just for demo */
	overflow: hidden;
}

.buttonMask {
	position: absolute;
	top: 0;
	right: 10px;
	text-indent: -30px;
	height: 100%;
	pointer-events: auto;
	z-index: 5;
	cursor: pointer;
	font-size: larger;
}
.buttonMask > svg {
	padding-top: 3px;
	height: 1.5em;
	width: 1.5em;
}

.formGroup > .toggleMask ~ input {
	padding-right: 30px;
}

.formGroup > .toggleMask:checked::before {
	content: '\e105';
}
