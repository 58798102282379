.listContainer {
	border: 1px solid #dadce0;
	border-radius: 10px;
	padding: 20px;
	width: 34vh; /* Default width for 768p */

	@media (min-width: 1280px) {
		/* 720p */
		width: 37vh;
	}

	@media (min-width: 1920px) {
		/* 1080p */
		width: 29vh;
	}
}

.containerTitle {
	color: #0176c0 !important;
	font-weight: bold;
}

.placeHolderTest > div{
	min-height: fit-content;
}
