.modal {
	font-family: var(--common-font);
	overflow-y: hidden;
}

.body {
	padding: 1vmax;
}

.headerContainer {
	border: none;
}

.header {
	display: flex;
	justify-content: space-between;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
}

.retryBtn {
	min-width: 40%;
}

.header .title {
	font-size: 1.2vw;
}

.footer {
	display: flex;
	justify-content: flex-end;
	padding: 0.1vmax;
	position: sticky;
	bottom: 0;
}

.footer .btn {
	min-width: 5vw;
}

.bodyContent {
	padding-bottom: 10vh;
}

.doneButton {
	width: clamp(50px, 10vw, 120px);
}

.subtext {
	margin-top: 2vh;
	font-size: 0.6vw;
}

.subTextNext {
	margin-bottom: 2vh;
	font-size: 0.6vw;
}

.contactDetailsErrorCloseBtn {
	min-width: 40%;
}

.errorBody {
	padding-left: 16px;
	padding-right: 16px;
}
.errorHeader {
	font-family: var(--common-font);
	font-size: clamp(10px, 1vw, 18px);
	margin-top: 0.7vh;
	margin-bottom: 2.5vh;
}

.errorText {
	font-family: var(--common-font);
	font-size: clamp(8px, 0.75vw, 13px);
	margin-bottom: 3vh;
}

.backBtn {
	position: absolute;
	top: 5%;
	left: 10px;
	min-width: 5vw;
}
