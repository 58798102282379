.modal {
	font-family: var(--common-font);
	overflow-y: auto;
	min-height: 65vh;
}

.body {
	padding: 1vmax;
}

.headerContainer {
	border: none;
	background-color: #fff !important;
}

.header {
	display: flex;
	justify-content: space-between;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
}

.retryBtn {
	min-width: 40%;
}

.header .title {
	font-size: 1.2vw;
}

.footer {
	display: flex;
	align-items: flex-end;
	flex-direction: row-reverse;
	justify-content: space-between;
	padding: 0.1vmax;
	bottom: 0;
}

.closeBtn {
	background-color: #fff !important;
	border: 1px solid rgb(190, 190, 190) !important;
	color: #4076e2 !important;
	font-weight: bolder;
	letter-spacing: 0.1rem;
	font-size: 0.6rem !important;
}

.footer .btn {
	min-width: 5vw;
}

.bodyContent {
	padding-bottom: 10vh;
}

.doneButton {
	width: clamp(50px, 10vw, 120px);
}

.subtext {
	margin-top: 2vh;
	font-size: 0.6vw;
}

.subTextNext {
	margin-bottom: 2vh;
	font-size: 0.6vw;
}

.contactDetailsErrorCloseBtn {
	min-width: 40%;
}

.errorBody {
	padding-left: 16px;
	padding-right: 16px;
}
.errorHeader {
	font-family: var(--common-font);
	font-size: clamp(10px, 1vw, 18px);
	margin-top: 0.7vh;
	margin-bottom: 2.5vh;
}

.errorText {
	font-family: var(--common-font);
	font-size: clamp(8px, 0.75vw, 13px);
	margin-bottom: 3vh;
}

.backBtn {
	position: absolute;
	top: 5%;
	left: 10px;
	min-width: 5vw;
}
.customLoader {
	width: 25px;
	height: 6px;
	background: radial-gradient(circle closest-side, #ffd6a5 90%, #0000) 0% 50%,
		radial-gradient(circle closest-side, #f3aa60 90%, #0000) 50% 50%,
		radial-gradient(circle closest-side, #f24c3d 90%, #0000) 100% 50%;
	background-size: calc(100% / 3) 100%;
	background-repeat: no-repeat;
	animation: d7 1s infinite linear;
}

@keyframes d7 {
	33% {
		background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
	}
	50% {
		background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
	}
	66% {
		background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
	}
}

.customLoaderContainter {
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.disclaimer {
	background-color: #fff;
	font-style: italic;
	font-size: 11px;
	padding-top: 0.25rem;
}
