.modal {
	padding: 1.4vmax;
}

.header {
	font-size: 20px;
	margin: 1vmax;
}

.fields {
	color: #8b9095;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.multiLine {
	height: 100px;
	color: #8b9095 !important;
}

/* Error Modal */
.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
}

.errorHeader {
	margin-bottom: 10px;
	font: normal normal 1.2vmax 'Poppins-Light', sans-serif;
}

.errorBody {
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.errorFooter {
	margin-top: 0;
	margin-bottom: 30px;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.rowBreak {
	word-break: break-all;
}

.paperContainers {
	padding: 0.7vmax 0.3vmax 0.7vmax 0.3vmax !important;
	border-radius: 20px;
}

.actions {
	display: flex;
}

.tooltip {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.tooltip > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.actions > div {
	margin: 5px 10px 5px 10px;
}

.actionIcon {
	font-weight: bold;
}

.actionHeader {
	text-align: center;
}

.filter * {
	font-size: 10px !important;
}

.paperContainer {
	padding: 0.7vmax 0.3vmax 0.7vmax 0.3vmax;
	border-radius: 20px;
}

.paperContainerFooter {
	text-align: right;
	margin-top: 12px;
	margin-right: 8px;
}

.totalTransIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	background: var(--unnamed-color-f26122) 0% 0% no-repeat padding-box;
	background: #bc224c 0% 0% no-repeat padding-box;
	border: 1px solid #00000000;
	border-radius: 23px;
	opacity: 1;
}
.totalCollectionIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	background: var(--unnamed-color-f26122) 0% 0% no-repeat padding-box;
	background: #4cb483 0% 0% no-repeat padding-box;
	border: 1px solid #00000000;
	border-radius: 23px;
	opacity: 1;
}

.table {
	margin: 0 !important;
	padding: 0 !important;
	border-right: 0 !important;
	min-height: 0;
}

.table > [role] > :not([role]) div {
	background-color: rgb(255, 255, 255) !important;
	font-weight: bolder !important;
	font-size: 12px !important;
	color: #000000de !important;
}

.table > [role] > [role] div:first-child {
	border-left: 1px solid rgb(255, 255, 255) !important;
	border-bottom-left-radius: 0 !important;
}

.table > [role] > [role] div:last-child {
	border-right: 1px solid rgb(255, 255, 255) !important;
	border-bottom-right-radius: 0 !important;
}

.table > [role='table'] > [role='rowgroup'] > [role='row']:nth-child(even) {
	background-color: #f3faff !important;
}

.table
	> [role='table']
	> [role='rowgroup']
	> [role='row']
	div
	div[role='cell'] {
	border: none !important;
}

.table > [role='table'] > [role='rowgroup'] > [role='row']:nth-child(odd) {
	background-color: #ffffff !important;
}

.table > [role='table'] > [role='rowgroup'] > [role='row']:first-child {
	border-top: 1px solid #dadce0 !important;
}

.table > [role='table'] > [role='rowgroup'] > [role='row'] div {
	padding-bottom: 3px !important;
}

.tabPanel > div {
	padding-top: 0 !important;
	min-height: auto;
}

.searchBar {
	width: 20vw !important;
	height: 30px;
}
.downloadButton {
	min-height: 30px !important;
}
.auditTrailButton {
	min-height: 30px !important;
}

.textField :global(.slds-input) {
	height: 1.7vmax !important;
	color: #3b3b3b;
	opacity: 1;
	font-size: 0.7vmax !important;
	padding-left: 0.7vmax !important;
}

.lookupTextField
	:global(.lookupPrefix__control)
	:global(.lookupPrefix__value-container) {
	padding-right: 2px !important;
	padding-left: 5px !important;
	height: 1.63vmax !important;
	font-size: 0.68vmax !important;
	color: #3b3b3b;
	opacity: 1;
	max-width: 90px;
}

.lookupTextLabel {
	margin-top: 0.25vmax !important;
	margin-bottom: 0 !important;
	font-size: 0.7vmax !important;
	color: #3b3b3b;
	opacity: 1;
}

.dateField :global(.slds-input) {
	margin-top: 0 !important;
	height: 1.5vmax !important;
	font-size: 0.7vmax !important;
}

.dateField :global(.slds-form-element__label) {
	margin-bottom: 0.05vmax !important;
}

.buttons {
	margin-top: 1.35vmax !important;
	padding: 0.5vw !important;
	font-size: 0.7vmax !important;
}

.selectField :global(.slds-button) {
	height: 1.5vmax !important;
}

@media screen and (max-width: 3000px) {
	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container),
	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__indicators) {
		height: 1.8vmax !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container)
		:global(.lookupPrefix__placeholder) {
		font-size: 0.61vmax !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container) {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 0.61vmax !important;
	}

	.lookupTextLabel div {
		margin-top: 0 !important;
		margin-bottom: 0.2vmax !important;
		font-size: 0.61vmax !important;
		color: #3b3b3b;
		opacity: 1;
	}

	.textField :global(.slds-form-element__control) :global(.slds-input) {
		height: 1.93vmax !important;
		color: #3b3b3b;
		opacity: 1;
		font-size: 0.7vmax !important;
		padding-left: 0.7vmax !important;
	}

	.dateField :global(.slds-form-element__control) :global(.slds-input) {
		height: 1.8vmax !important;
		font-size: 0.61vmax !important;
		padding-right: 0 !important;
		padding-left: 0.3vmax !important;
	}

	.dateField :global(.slds-form-element__label) label {
		margin-top: 0 !important;
		font-size: 0.61vmax !important;
	}

	.dateField :global(.slds-form-element__label) {
		margin-top: 0.1vmax !important;
		padding: 0 !important;
	}

	.selectField :global(.slds-button) {
		font-size: 0.61vmax !important;
	}

	.buttons {
		margin-top: 1.35vmax !important;
		padding: 0.5vw !important;
		font-size: 0.7vmax !important;
	}
}

@media screen and (max-width: 1000px) {
	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container),
	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__indicators) {
		height: 1.7vmax !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container)
		:global(.lookupPrefix__placeholder) {
		margin: 0 !important;
		padding: 0 !important;
	}

	.lookupTextField
		:global(.lookupPrefix__control)
		:global(.lookupPrefix__value-container) {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 0.61vmax !important;
	}

	.dateField :global(.slds-form-element__control) :global(.slds-input) {
		height: 1.8vmax !important;
		font-size: 0.61vmax !important;
		padding-left: 0.3vmax !important;
		margin-top: 0 !important;
	}

	.dateField :global(.slds-form-element__control) :global(.slds-button) {
		margin-right: 0 !important;
	}

	.dateField :global(.slds-form-element__label) {
		margin-top: 0 !important;
		margin-bottom: 0.2vmax !important;
	}

	.textField :global(.slds-form-element__control) :global(.slds-input) {
		height: 1.93vmax !important;
		color: #3b3b3b;
		opacity: 1;
		font-size: 0.61vmax !important;
	}

	.selectField :global(.slds-button) {
		font-size: 0.61vmax !important;
	}

	.buttons {
		margin-top: 1.35vmax !important;
		padding: 0.5vw !important;
		font-size: 0.7vmax !important;
	}
}
