.confirmationHeader {
	font-size: 18px;
}

.subtext {
	margin-top: 2vh;
	font-size: 0.7vw;
}

.successBtn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	height: 40px;
	border-radius: 4px;
}
