.modal {
	align-self: center;
	border-radius: 0.25rem;
	width: 27vw;
}

.larger {
	width: 23vw;
}

.modalContainer {
	align-self: center;
	top: -10vh;
}

.container {
	padding: 1vw;
}

.iconContainer {
	display: flex;
	justify-content: center;
	padding-top: 10px;
}

.modalBody > div {
	font-family: var(--common-font);
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 1em 0;
}

.modalActions > div {
	display: flex;
	justify-content: center;
}

.header button {
	display: none;
}

.downloadText {
	font: normal normal 600 18px/21px 'Wavehaus-SemiBold', sans-serif;
}

.button {
	margin: 0px 17px;
}

.padBottom {
	padding-bottom: 23px;
}
