.statusEnabled {
	color: #14bb02;
	padding-left: 8px;
	margin-left: 9px;
}

.statusDisabled {
	color: #c84031;
	padding-left: 8px;
	margin-left: 9px;
}

.idCell {
	font-weight: bold;
}

/*Table*/
.tableContainer {
	> div {
		overflow-x: hidden;
		overflow-y: hidden;
	}
}

.sectionContainer {
	padding: 0;
	margin: 0;
}

.paperContainer {
	padding: 0;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.paperContainer > [role='table'] > [role='rowgroup'] > [role='row'] div {
	padding-bottom: 3px !important;
}

.auditTrailContainer > [role='table'] > [role='rowgroup'] > [role='row'] div {
	padding-bottom: 3px !important;
}

.tableContainerTabContent {
	margin: 0 0 10px 0 !important;
	padding: 0 0 10px 0 !important;
}

.tableContainerTabContent > [role='table'] > [role='rowgroup'] > [role='row'] {
	padding-bottom: 3px;
}

.tableContainerTabContent
	> [role='table']
	> [role='rowgroup']
	> [role='row']
	div {
	padding-bottom: 0;
	padding-left: 0;
}

.tableContainerAuditTrail {
	margin: 0 0 10px 0 !important;
	padding: 0 0 10px 0 !important;
}

.tableContainerAuditTrail > [role='table'] > [role='rowgroup'] > [role='row'] {
	padding-bottom: 3px;
}

.tableContainerAuditTrail
	> [role='table']
	> [role='rowgroup']
	> [role='row']
	div {
	padding-bottom: 3px !important;
}
/*ExpandedSection Component*/

.expandedSection {
	position: relative;
	width: 896px;
}

.expandedIdCell {
	padding: 4px 27px 4px 5px;
	text-align: center;
}

.expandedActionCell {
	padding: 4px 17px 4px 10px;
	/* text-align: center; */
}

.expandButton {
	position: relative;
	bottom: 10px;
}

.expandedButton {
	padding-top: 2px;
	padding-bottom: 7px;
}

.sectionClosed {
	height: 0px;
}

/*Success Modal*/
.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successBody {
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

/*Error Modal*/
.errorHeader {
	font-weight: bold;
	font-size: 0.9vmax;
	margin-bottom: 10px;
}

.errorBody {
	margin-top: 20px;
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.divider {
	border-top: 0.35vh solid #0176c0;
	margin: 0vmax;
}

.expandedSectionRow {
	border-bottom: 1px solid #dadfe2;
	border-top: 1px solid #dadfe2;
	background-color: white;
}

.expandedSectionRow:last-child {
	border-bottom: 0;
	border-top: 1px solid #dadfe2;
}

.expandStateBranch {
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.expandedSectionTd {
	text-align: right;
	padding-top: 10px;
}

.expandedSectionTdName {
	width: 21.8%;
}

.bodyHeaderEmphasis {
	font-weight: bold;
}

.action {
	color: var(--orange);
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
	display: inline-block;
}

.actionContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.customModalHeader {
	padding: 1vmax;
	font-size: 22px;
}

.expandedButtons {
	padding-left: 10px;
	padding-top: 10px;
}

@media screen and (min-width: 1710px) {
	.expandedSection {
		position: relative !important;
		width: 1143px !important;
	}

	.statusEnabled {
		color: #14bb02;
		padding-left: 12px;
		margin-left: 14px;
	}

	.statusDisabled {
		color: #c84031;
		padding-left: 12px;
		margin-left: 14px;
	}
}

/*Partner Tab CSS START*/
.tabContainer {
	margin-left: 24px;
	margin-right: 24px;
}

.tabPanel > div {
	padding-top: 10px !important;
	min-height: auto;
}
/*Partner Tab CSS END*/
