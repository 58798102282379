.vspaced>* {
    margin-bottom: 0.5vh;
}

.dateTimeBorder {
    border: 1px solid #dadce0;
    border-radius: 5px;
    padding: 10px !important;
    font-family: var(--common-font);
    width: 100%;
    margin-top: 20px;
    margin-right: 20px;
}

.scheduleRow {
    padding: 20px !important;
}