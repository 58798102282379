.modal {
	align-self: center;
	border-radius: 0.25rem;
	width: fit-content;
	width: 20vw;
}

.larger {
	width: 29vw;
}

.modalContainer {
	/* align-self: center; */
	top: 0vh;
}

.container {
	padding: 0vw;
}

.modalBody > div {
	font-family: var(--common-font);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	/* margin: 1em 0; */
}

.modalActions > div {
	display: flex;
	justify-content: right;
}

.header button {
	display: none;
}

.uploadSuccessText {
	opacity: 0.7;
	margin-top: 1vh;
	margin-bottom: 2vh;
}

.uploadSuccessSubtext {
	width: 70%;
	font-size: 1.5vw;
	margin-bottom: 2vh;
}

.uploadSuccessTable > thead {
	font-size: 40px;
	text-align: center;
}

/* .uploadSuccessHeader {
	font-family: var(--common-font-bold);
	font-weight: bold;
	margin: 0;
} */
