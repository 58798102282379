.fontWavehaus {
    font-family: 'Wavehaus-SemiBold', sans-serif;
}

.fontPoppins {
    font-family: 'Poppins-Regular', sans-serif;
}

a, a:hover, a:active, a:focus {
    text-decoration: none;
    color: #000000;
}

.contact {
    font-size: 14px;
    display: block;
    padding-bottom: 10px;
}
