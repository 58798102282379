.container {
	font-family: var(--common-font);
}

.title {
	font-family: var(--common-font-bold);
	font-size: 1.2vw;
	margin-bottom: 2vh;
}

.header {
	margin-bottom: 3.1vh;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
}

.row {
	margin-bottom: 2vh;
}
