.slds-checkbox_toggle .slds-checkbox_faux:after {
	width: 0.7rem;
	height: 0.7rem;
	border: none !important;
	margin-top: -1px;
	background-color: var(--sds-c-checkbox-toggle-switch-color-background, gray);
}

.slds-checkbox_toggle
	[type='checkbox']:checked
	+ .slds-checkbox_faux_container
	.slds-checkbox_faux:after {
	border-bottom-color: transparent !important;
	border-right-color: transparent !important;
}

.slds-checkbox_toggle
	[type='checkbox']:checked
	+ .slds-checkbox_faux_container
	.slds-checkbox_faux:before {
	/* left: 1rem !important; */
	width: 0.7rem !important;
	height: 0.7rem !important;
	border-color: #f26122 !important;
	background-color: #f26122 !important;
	margin-top: -1px;
	margin-right: -5px;
}

.slds-checkbox_toggle
	[type='checkbox']:checked
	+ .slds-checkbox_faux_container
	.slds-checkbox_faux {
	border-color: #f1bba3 !important;
	background-color: #f1bba3 !important;
}

.slds-checkbox_toggle .slds-checkbox_faux_container .slds-checkbox_faux {
	width: 27px;
	height: 13px;
}
