.paperContainer {
	padding: 1vmax;
	margin-top: 41px;
	border-radius: 10px;
}

.title {
	font: normal 600 24px/24px var(--alt-font);
	letter-spacing: 0.74px;
}

.mainContainer {
	margin-top: 13px;
}

.submitRemarks {
	resize: none;
	height: 100px;
}

.submitRemarksSubLabel {
	font-size: 11px;
	font-family: 'Poppins-Regular', sans-serif;
	color: #8b9095 !important;
}

.updateStatus {
	justify-content: left;
}

.updateStatusBody1 {
	text-align: center;
	margin-bottom: 20px;
}

.updateStatusBody2 {
	text-align: left;
}

.updateStatusRemarks {
	resize: none;
	width: 12px;
}

.updateStatusSubLabel {
	font-size: 11px;
	font-family: 'Poppins-Regular', sans-serif;
	color: #8b9095 !important;
	text-align: left;
}

.approveHeading {
	top: 250px;
	left: 602px;
	font-size: 14px;
	font-weight: 600;
	font-stretch: condensed;
}

.tabMaxheightAndWidth {
	height: 32rem;
}

.placeholderImg {
	margin: 0 auto;
	display: block;
}

.placeholderTitle {
	font: normal normal 14px/21px var(--common-font-bold);
	color: #f26122;
	text-align: center;
	margin-top: 11px;
}

.placeholderSubTitle {
	color: #3b3b3b;
	font: normal normal 12px/18px var(--common-font);
	text-align: center;
	margin-top: 4px;
}
