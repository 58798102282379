.cardTitle {
	color: var(--orange);
	font-size: 1.2vw;
	font-family: var(--common-font);
}

.divCardContainer {
	margin-top: 3vh;
	border: 2px solid var(--orange);
	padding: 1.5vmax;
	padding-bottom: 0.5vmax;
	border-radius: 10px;
	font-family: var(--common-font);
	font-size: 0.7vw;
}
