.groupControl :global(.slds-accordion__summary) {
  padding-top: 0;
  padding-bottom: 10px;
}

.groupControl :global(.slds-accordion__summary .slds-accordion__summary-action) {
  padding-top: 0;
  margin-top: 0;
  text-decoration: none !important;
  user-select: none !important;
}

.groupControl :global(.slds-accordion__summary .slds-button__icon) {
  display: none;
}

.groupControl :global(.slds-accordion__section) {
  padding-left: 0;
  padding-right: 0;
}

.container {
  border: 1px solid #dadce0;
  min-height: 50px;
  margin-left: 0;
  padding-left: 0;
}

.summary, .summary:hover, .summary:active {
  text-decoration: none !important;
  color: #8b9095 !important;
  font-size: 0.8vw;
  padding-top: 0;
}

.summaryActionIcon {
  margin-left: 5px;
  width: 0.93em;
  height: 0.83em;
}

.summaryTooltipIcon {
  margin-left: 5px;
}

.groupControl :global(.slds-tooltip-trigger) {
  /* margin-left: 5px; */
}

.groupControl :global(.slds-popover_tooltip) {
  /* background-color: #8b9095; */
}

.contentTitle {
  font-family: var(--common-font-bold);
  font-size: 1vw;
  margin-bottom: 2vh;
	position: relative;
}

.content {
  border: 1px solid #dadce0;
  padding: 15px;
  color: default;
}
