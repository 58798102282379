.cardContainer {
	width: 18.5vw;
	height: 17vh;
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 6px #00000029;
	border-radius: 9px;
	opacity: 1;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: 20px;
}

.cardDivider {
	width: 16vw;
	height: 0px;

	border: 1px solid #dadfe2;
	opacity: 1;

	margin-top: 25px;
	margin-left: 1.3vw;
}

.cardHeader {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.cardIcon > svg {
	height: 5vh;
	width: 6vw;
}

.cardFooter {
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	justify-content: space-between;
	padding: 1vh 0 1vh 1.2vw;
	text-align: left;
	font: normal normal normal 12px/18px 'Poppins-Regular', sans-serif;
	letter-spacing: 0px;
	color: #8b9095;
	opacity: 1;
}

.cardStatusEnabled {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	text-align: left;
	font: normal normal 600 9px 'Poppins-Regular', sans-serif;
	color: #14bb02;
	opacity: 1;
}

.cardStatusDisabled {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	text-align: left;
	font: normal normal 600 9px 'Poppins-Regular', sans-serif;
	color: #c84031;
	opacity: 1;
}

.cardTitle {
	cursor: pointer;
}

.cardTitle:active,
.cardTitle:hover {
	font-weight: bolder;
	text-decoration: underline;
}

.tooltip {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.tooltip > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

/*Success Modal*/
.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successBody {
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

/*Error Modal*/
.errorHeader {
	font-weight: bold;
	font-size: 0.9vmax;
	margin-bottom: 10px;
}

.errorBody {
	margin-top: 20px;
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.bodyHeaderEmphasis {
	font-weight: bold;
}
