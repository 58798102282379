.navContainer {
	padding-top: 5vh;
	border-radius: 0 30px 0 0;
	box-shadow: 0 3px 6px #00000029;
	background-color: #fff;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.navTitle {
	padding: 1.5vh 1vw;
	font-size: 0.7vw;
	font-family: var(--common-font-bold);
}

.navTitleExpanded {
	background: var(--blue);
	letter-spacing: 0.21px;
	color: #ffffff;
}

.navTitle img {
	width: 0.8vw;
	height: 0.8vw;
	margin-right: 1em;
}

.navTitleCollapsed {
	background-color: #ffffff;
	letter-spacing: 0.21px;
	color: #3b3b3b;
	padding-bottom: 3vh !important;
}

.navSection {
	background-color: #e9f5fd;
	padding-top: 1vh;
	min-height: 5vh;
}

.version {
	padding-bottom: 1vh;
	padding-top: 1vh;
	text-align: center;
	width: 100%;
	font-family: var(--common-font-bold);
	font-size: 0.7vw;
}

.accordionSection {
	padding: 0;
	padding-top: 1em;
}

.accordionButton {
	padding-top: 0;
	padding-bottom: 0;
}

.accordionButton:focus {
	box-shadow: none !important;
}

.fullWidth {
	width: 100%;
}

.bayadLogoContainer img {
	width: 8vw;
	margin-bottom: 3vh;
}

.navListContainer {
	overflow-y: auto;
	overflow-x: hidden;
	height: 72vh;
}
