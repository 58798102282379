.filterContainer,
.filterContainer > div:first-child {
	padding: 0 !important;
}

.innerButtons {
	padding-top: 2.2em;
}

.filters > div,
.innerButtons > div {
	padding-left: 0.2em !important;
	padding-right: 0.2em !important;
}

.innerButtons button {
	max-height: none !important;
}

.textFieldLabel > div > div > div:first-child {
	margin-bottom: 0 !important;
}

.textFieldLabel > div > div > div:first-child > div {
	font-size: var(--input-label-font-size) !important;
	color: #3b3b3b;
	font-family: var(--common-font);
	letter-spacing: 0;
	padding-top: 0.25rem;
	margin-bottom: 0.125rem;
}
.textFieldLabel > div > div > div:nth-child(2) > div > input {
	padding: 0.4vmax !important;
	font-size: var(--input-label-font-size) !important;
	padding-left: 1vw !important;
	line-height: 2vh !important;
	height: 1.65rem;
}
.frequencyDropdown {
	position: relative;
	top: 9px;
}

.multiSelectLabel > div:first-child > div {
	padding-top: 0;
	margin-bottom: 0;
	height: 1.25rem;
}

/* label */
.multiSelectLabel > div:first-child > div > div {
	color: #3b3b3b;
	font-family: var(--common-font);
	letter-spacing: 0;
	font-size: var(--input-label-font-size) !important;
	padding-top: 0.15rem !important;
	/* padding-top: 0 !im; */
}

/* button */
.multiSelectLabel > div:first-child > div > div {
	height: 1.75rem;
}

.multiSelectLabel > div:nth-child(2) > div {
	color: #3b3b3b;
	font-family: var(--common-font);
	letter-spacing: 0;
}

/* .textFieldLabel > div > div > div:last-child input {
	line-height: 1.5vh !important;
} */
