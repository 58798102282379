.tableContainer > div {
    width: 100%;
}

.tableContainer > div > div > div > div > div {
    justify-content: flex-start;
}

.tableContainer {
    width: 75%;
}