.tableContainer,
.tableContainer > div:first-child {
	padding: 0.5vmax 0 !important;
}

.iconBtn {
	width: 30px;
	height: 30px;
}

.crossIcon,
.crossIcon:focus {
	color: var(--orange);
}

.crossIcon:hover {
	color: var(--darkerOrange);
}

.actionCell button {
	width: min(1vmax, 20px);
	height: min(1vmax, 20px);
}

.actionHeader {
	text-align: center;
	width: 100%;
}

.toggleButtonContainer {
	padding: 5px 3px 5px 15px;
}

.toggleButtonContainer :global(.slds-checkbox_faux:after) {
	width: 0.7rem;
	height: 0.7rem;
	border: none !important;
}

.toggleButtonContainer :global(.slds-checkbox_faux:before) {
	left: 1rem !important;
	width: 0.7rem !important;
	height: 0.7rem !important;
}

.toggleButtonContainer:global(.slds-checkbox_toggle [type='checkbox']:checked) {
	display: none;
}

.toggleButtonContainer :global(.slds-checkbox_faux) {
	width: 30px;
	height: 15px;
}

.toggleButtonContainer:global(
		.slds-checkbox_toggle
			[type='checkbox'][disabled]
			+ .slds-checkbox_faux_container
			.slds-checkbox_faux:after
	) {
	background-color: #cbcbcb;
}

.enabledStatusFont {
	color: #14bb02 !important;
	font-weight: bold;
}

.disabledStatusFont {
	color: #ff0000 !important;
	font-weight: bold;
}

.modalReportName {
	font-weight: bold;
}

.toolTip {
	display: block !important;
	line-height: normal !important;
}

.toolTip > div[role='tooltip'] {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
	margin-left: -3.8vw !important;
}

.toolTip > div[role='tooltip'] > * {
	color: #3b3b3b !important;
}
