.errorModal {
	align-self: center;
	border-radius: 0.25rem;
	width: fit-content;
	min-width: 20vw;
}

.errorModalContainer {
	top: -10vh;
	padding: 1vw;
}

.errorModalContent {
	padding: 18.5px;
}

.errorIcon {
	fill: red;
}

.errorModalIconContainer {
	display: flex;
	justify-content: center;
}

.errorModalBody > div {
	font-family: var(--common-font);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	margin-top: 22px;
	font-size: 0.8vw;
}

.errorModalActions > div {
	display: flex;
	justify-content: center;
}

.errorModalHeader button {
	display: none;
}

.errorModalText {
	font-size: 0.8vw;
	font-weight: bold;
	margin-top: 1vh;
	margin-bottom: 2vh;
	width: 40vh;
}

.errorModalSubtext {
	width: 90%;
	font-size: 0.7vw;
	margin-bottom: 2vh;
}

.errorHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.boldText {
	font-family: var(--common-font-bold);
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
