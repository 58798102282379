.container {
	padding-top: 1vmax;
}

.container > * {
	margin-bottom: 2vh;
}

.paymentAcceptancePolicy {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.paymentAcceptancePolicy :global(.slds-radio) {
	width: auto;
}

.sectionTitle {
	font-family: var(--common-font-bold);
	font-size: 1vw !important;
	margin-bottom: 2vh;
}
