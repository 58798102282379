.container {
	padding-top: 1vmax;
}

.container > * {
	margin-bottom: 2vh;
}

.accreditatioRequirementsTitle {
	font-family: var(--common-font-bold);
}

.accreditatioRequirementsTitle :global(.slds-tooltip-trigger) {
	margin-left: 5px;
}

.accreditatioRequirementsTitle :global(.slds-popover_tooltip) {
	background-color: #8b9095;
}

.noMarginBottom {
	margin-bottom: 0 !important;
}

.noMarginSide {
	margin: 0vw;
}
