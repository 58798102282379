.placeholderTitle {
	font: normal normal 14px/21px var(--common-font-bold);
	color: #f26122;
	text-align: center;
	margin-top: 11px;
}

.placeholderSubTitle {
	color: #3b3b3b;
	font: normal normal 12px/18px var(--common-font);
	text-align: center;
	margin-top: 4px;
}

.placeholderImg {
	margin: 0 auto;
	display: block;
	margin-top: -22vh;
}

.tabMaxheightAndWidth {
	height: 50vh;
}
