.fontPoppins {
    font-family: 'Poppins-Regular', sans-serif;
}

.fontPoppinsLight {
    font-family: 'Poppins-Light', sans-serif;
}

.imgHardLight {
    width: 55%;
    mix-blend-mode: hard-light;
}
