.container {
	font-family: var(--common-font);
	border: 2px solid #f26122;
	border-radius: 50px;
	padding: 17px 23px 5px 23px;
	margin-bottom: 15px;
	background-color: #fff;
}

.container label {
	font: normal normal 0.8vmax 'Poppins-Regular', sans-serif !important;
	display: block;
}

.container h1 {
	font: normal normal 3.5vh 'Wavehaus-SemiBold', sans-serif;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.header {
	font-family: var(--common-font-bold);
	font-size: 2.1vh;
	letter-spacing: 0px;
	color: #3b3b3b;
	margin-bottom: 2.2vh;
}

.divider {
	border-top: 0.5vh solid #0176c0;
}

.footer {
	display: flex;
	justify-content: flex-end;
	padding: 0.1vmax;
	position: sticky;
	bottom: 0;
}

.footer .btn {
	min-width: 5vw;
}

.contactDetailsErrorCloseBtn {
	min-width: 40%;
}

.walletInput {
	margin-bottom: 10px;
}

.walletRadioContainer {
	text-align: right;
}

.pill {
	align-items: center;
	font-weight: bold;
	letter-spacing: 0.7px;
	font-size: 0.8vw;
	display: inline-block;
	height: 100%;
	white-space: nowrap;
	width: auto;

	position: relative;
	border-radius: 100px;
	overflow: hidden;
	padding: 0 1.2vw 0 1.2vw;
	text-overflow: ellipsis;
	line-height: 1.8vw;
	color: #effaee;

	word-break: break-word;
}

.responsibilityCheckbox span > label > span {
	padding-right: 50 !important;
	font-size: 1.5vh !important;
}

.incidentReportBox {
	margin-bottom: 0.5vh;
	margin-left: 0.5vw;
	margin-right: 1vw !important;
}

.topSticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background: #fff;
	z-index: 3;
	padding-top: 2.8vh;
	padding-bottom: 0.3vh;
	display: flex;
	justify-content: space-between;
}

.bottomSticky {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	background: #fff;
	z-index: 4;
	padding-top: 2vh;
	padding-bottom: 0.6vh;
}

.addressErrorCloseBtn {
	padding: 2.3vh;
}

.addressUploadErrorCloseBtn {
	padding: 15px 40px !important;
}

.outlineSuccessBtn {
	color: #f26122 !important;
	background: #ffffff !important;
	border: 1px solid #f26122 !important;
	border-radius: 4px !important;
	opacity: 1 !important;
	font-size: 14px;
	white-space: nowrap !important;
	padding: 2.3vh !important;
}

.outlineSuccessBtn:hover {
	color: #ffffff !important;
	background: #f26122 !important;
	border: 1px solid #f26122 !important;
}

.subtext {
	color: #969698;
	font-size: 0.8vw;
}

.successHeader {
	margin-bottom: 2vh;
	font-size: 1vw;
	padding-top: 1vw;
}
.succcessMessage > div {
	font-size: 5vw;
}

.trnText {
	margin-bottom: 3.2vh;
	font-size: 0.8vw;
}

.tableErrorUpload {
	height: 40vh;
	overflow: scroll;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 2px;
}

.tableErrorUpload div[class*='Table_paperContainer'] {
	padding: 0 !important;
}
.tErrorUpload {
	height: 45vh;
	overflow: scroll;
	margin-left: 32px;
	margin-right: 32px;
	margin-bottom: 5px;
}

.tErrorUpload div[class*='Table_paperContainer'] {
	padding: 0 !important;
}

.uploadSuccessErrorText {
	font-family: var(--common-font-bold);
	font-size: 18px;
	font-weight: bold;
	padding-top: 20px;
	padding-bottom: 10px;
	padding-right: 25px;
	padding-left: 25px;
	margin: 0;
}

.uploadSuccessErrorSubText {
	font-family: var(--common-font-bold);
	padding-bottom: 0px;
	padding-right: 25px;
	padding-left: 25px;
	margin: 0;
}

.uploadSuccessErrorBody {
	margin-left: 26px;
	margin-right: 26px;
}

.walletRepUploadFieldContainer button {
	margin-top: 24px !important
}


.scanningIcon {
	fill: var(--orange);
	width: 3.6vw;
}
