.datePickerContainer {
	min-width: 15%;
}

.filters {
	padding: 1vw 0.75vw 0.75vw !important;
}

.filters > div[class]:first-child > div[class] > div[class] {
	margin-bottom: 0.75vw !important;
}

.filters > div[class]:last-child {
	margin-top: 0.3vw !important;
}

.filters > div[class] > div[class] > div:first-child {
	font-size: 1.4vw !important;
}

.lookupTextField
	:global(.lookupPrefix__control)
	:global(.lookupPrefix__value-container) {
	height: 2vw !important;
	max-width: 160px;
}

.lookupTextLabel {
	margin-top: 0.2vw !important;
	margin-bottom: 0.32vw !important;
	font-size: 0.75vmax !important;
	color: #3b3b3b;
	opacity: 1;
}

.button {
	width: 8vmax !important;
}

.buttons {
	margin-top: 1.6vmax !important;
	padding: 1vmax !important;
}

.selectLabel {
	font-size: 0.75vmax !important;
	color: #3b3b3b;
	opacity: 1;
}
