.dropdownButton {
	width: 100%;
	font-family: var(--common-font-light);
	font-size: var(--input-label-font-size);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdownInitState {
	color: #8b9095;
}

.dropdownActiveState {
	color: #3b3b3b;
}

.dropdownTriggerButton {
	font-family: var(--common-font);
	display: block;
}

.dropdownTriggerButton > button > svg {
	fill: #8b9095;
}

.dropdownItem > ul > li > a:hover {
	color: var(--orange);
}

.dropdownItem > ul > li > a {
	justify-content: flex-start;
	gap: 2px;
}

li.isNew > a::after {
	content: '(new)';
}

li.isUpdated > a::after {
	content: '(updated)';
}

li.isNew > a::after, li.isUpdated > a::after {
	position: relative;
	top: -0.5em;
	font-size: 9px;
	color: #00be00;
	font-style: italic;
}

.dropdownContainer {
	position: relative;
	/* width: 35%; */
}

.slds-dropdown_medium {
	min-width: 100% !important;
}
