button.primary {
	height: 100%;
	color: #ffffff;
	background: #f26122;
	border: 1px solid #f26122;
	border-radius: 4px;
	opacity: 1;
	font-size: 14px;
}

button.primary:hover,
button.primary:active,
button.primary:focus {
	background: #e1571c;
	border: 1px solid #e1571c;
}

button.primary:disabled {
	background: #f6a07b;
	border: none;
}
