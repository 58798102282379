.searchBar {
	width: 100%;
}

.downloadButton {
	height: 100%;
}

.statusApproved {
	color: #14bb02;
}
.statusRejected {
	color: #ff0000;
}
.statusPending {
	color: #ffa500;
}
.statusExpired {
	color: #8b9095;
}

.innerButtons {
	padding-top: 1.9em;
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}
.checkIcon {
	fill: #14bb02;
}

.crossIcon,
.crossIcon:focus {
	color: var(--orange);
}

.crossIcon:hover {
	color: var(--darkerOrange);
}

.downloadIcon {
	color: gray;
}

.downloadIcon:hover {
	color: gray;
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 1em;
	font-size: 0.7vw;
	color: #3e3f42;
}

.filters > div,
.innerButtons > div {
	padding-left: 0.2em;
	padding-right: 0.2em;
}

.serviceTypeContainer {
	min-width: 20%;
}

.datePickerContainer {
	min-width: 15%;
}

.clearFilterBtnContainer {
	min-width: 60%;
}

.retryBtn {
	max-width: 50%;
	font-size: 2px;
}

.actionCell button {
	width: min(2.5vmax, 30px);
	height: min(1vmax, 20px);
}

.actionHeader {
	text-align: center;
	width: 100%;
}
@media screen and (min-width: 1337px) and (max-width: 1920px) {
	.column {
		margin-left: 0.4vw;
	}
	.fileName {
		padding-left: 0.2vw;
	}
	.fileType {
		margin-left: 0.3vw;
	}
}
@media (max-width: 1336px) {
	.column {
		margin-left: 0.4vw;
	}
	.fileName {
		padding-left: 0.2vw;
	}
	.fileType {
		margin-left: 0.3vw;
	}
}
.actions {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.customLoader {
	width: 25px;
	height: 6px;
	background: radial-gradient(circle closest-side, #ffd6a5 90%, #0000) 0% 50%,
		radial-gradient(circle closest-side, #f3aa60 90%, #0000) 50% 50%,
		radial-gradient(circle closest-side, #f24c3d 90%, #0000) 100% 50%;
	background-size: calc(100% / 3) 100%;
	background-repeat: no-repeat;
	animation: d7 1s infinite linear;
}

@keyframes d7 {
	33% {
		background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
	}
	50% {
		background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
	}
	66% {
		background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
	}
}
