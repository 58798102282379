.headerRow {
	padding: 0.3em 0;
	max-width: 400px;
	width: 400px;
}

.bold {
	font-weight: bold;
}

.container {
	margin-left: -1.6vw;
}
