.statusEnabled {
	color: #14bb02;
}

.statusDisabled {
	color: #c84031;
}

.statusActiveNoSched {
	color: #f26122;
}

.statusClosed {
	color: #8b9095;
}

.statusEnabled,
.statusDisabled,
.statusActiveNoSched,
.statusClosed {
	padding-left: 8px;
	margin-left: 7px;
}

.expandedStatusEnabled {
	color: #14bb02;
}

.expandedStatusDisabled {
	color: #c84031;
}

.expandedStatusActiveNoSched {
	color: #f26122;
}

.expandedStatusClosed {
	color: #8b9095;
}

.idCell {
	font-weight: bold;
	margin-left: 7px !important;
	margin-bottom: 7px;
}

/*Table*/
.tableOverflow > div {
	overflow: hidden;
}

.filterContainerTabContent {
	margin: 0 0 10px 0 !important;
	padding: 0 0 10px 0 !important;
}

.tableContainerTabContent {
	margin: 0 0 10px 0 !important;
	padding: 0 0 10px 0 !important;
}

.tableContainerTabContent > [role='table'] {
	padding-bottom: 10px !important;
}

.tableContainerTabContent
	> [role='table']
	> [role='rowgroup']
	> [role='row']
	div {
	padding-bottom: 0 !important;
	padding-left: 0;
}

.tableContainerTabContent :global(.slds-tooltip-trigger) > [role='tooltip'] {
	padding-left: 12px !important;
}
/*ExpandedSection Component*/
.expandedSection {
	position: relative;
	width: 896px;
	background-color: #f3faff;
}

.expandedSectionRow .expandedStatusEnabled,
.expandedSectionRow .expandedStatusDisabled,
.expandedSectionRow .expandedStatusActiveNoSched,
.expandedSectionRow .expandedStatusClosed {
	padding-left: 8px;
	margin-left: 9px;
}

.expandedSectionRow:first-child {
	border-top: 1px solid #dadfe2;
}

.expandedSectionRow:last-child {
	border-bottom: 0;
}

.expandedIdCell {
	width: 26vw;
	padding: 4px 27px 4px 5px;
	text-align: center;
}

.expandedActionCell {
	padding: 4px 17px 4px 10px;
	width: 24vw;
}

.expandButton {
	position: relative;
	bottom: 10px;
}

.expandButtonIcon {
	margin-left: 4.7vw;
	margin-top: 0;
	padding-top: 0.8vw;
}

.sectionClosed {
	height: 0px;
}

/*Success Modal*/
.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successBody {
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

/*Error Modal*/
.errorHeader {
	font-weight: bold;
	font-size: 0.9vmax;
	margin-bottom: 10px;
}

.errorBody {
	margin-top: 20px;
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

/* Action Cell */
.actionBtns {
	display: flex;
	align-items: center;
}

.disabled * {
	cursor: not-allowed !important;
}

.enabledTpaid {
	margin-left: 8px;
	color: #8b9095;
}

.tooltip {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.tooltip > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.bodyHeaderEmphasis {
	font-weight: bold;
}

.expandedSectionRow td {
	padding: 0.5vw;
}

.successBody {
	font-weight: bold;
}

@media screen and (max-width: 1800px) {
	.expandedSection {
		width: 1137px !important;
	}

	.statusEnabled,
	.statusDisabled,
	.statusActiveNoSched,
	.statusClosed {
		margin-left: 7px !important;
	}

	.expandedSectionRow .expandedStatusEnabled,
	.expandedSectionRow .expandedStatusDisabled,
	.expandedSectionRow .expandedStatusActiveNoSched,
	.expandedSectionRow .expandedStatusClosed {
		padding-left: 17px !important;
		margin-left: 7px !important;
	}
}

@media screen and (max-width: 1600px) {
	.expandedSection {
		width: 1003px !important;
	}

	.statusEnabled,
	.statusDisabled,
	.statusActiveNoSched,
	.statusClosed {
		margin-left: 7px !important;
	}

	.expandedSectionRow .expandedStatusEnabled,
	.expandedSectionRow .expandedStatusDisabled,
	.expandedSectionRow .expandedStatusActiveNoSched,
	.expandedSectionRow .expandedStatusClosed {
		padding-left: 14px !important;
		margin-left: 7px !important;
	}
}

@media screen and (max-width: 1500px) {
	.expandedSection {
		width: 897px !important;
	}

	.statusEnabled,
	.statusDisabled,
	.statusActiveNoSched,
	.statusClosed {
		margin-left: 8px !important;
	}

	.expandedSectionRow .expandedStatusEnabled,
	.expandedSectionRow .expandedStatusDisabled,
	.expandedSectionRow .expandedStatusActiveNoSched,
	.expandedSectionRow .expandedStatusClosed {
		padding-left: 12px !important;
		margin-left: 7px !important;
	}
}

@media screen and (max-width: 1300px) {
	.expandedSection {
		width: 811px !important;
	}

	.statusEnabled,
	.statusDisabled,
	.statusActiveNoSched,
	.statusClosed {
		margin-left: 7px !important;
	}

	.expandedSectionRow .expandedStatusEnabled,
	.expandedSectionRow .expandedStatusDisabled,
	.expandedSectionRow .expandedStatusActiveNoSched,
	.expandedSectionRow .expandedStatusClosed {
		padding-left: 10px !important;
		margin-left: 6px !important;
	}
}

@media screen and (max-width: 1100px) {
	.expandedSection {
		width: 706px !important;
	}

	.statusEnabled,
	.statusDisabled,
	.statusActiveNoSched,
	.statusClosed {
		margin-left: 7px !important;
	}

	.expandedSectionRow .expandedStatusEnabled,
	.expandedSectionRow .expandedStatusDisabled,
	.expandedSectionRow .expandedStatusActiveNoSched,
	.expandedSectionRow .expandedStatusClosed {
		padding-left: 6px !important;
		margin-left: 5px !important;
	}
}

@media screen and (min-width: 1710px) {
	.expandedSection {
		width: 1141px !important;
	}

	.statusEnabled,
	.statusDisabled,
	.statusActiveNoSched,
	.statusClosed {
		margin-left: 12px !important;
	}

	.expandedSectionRow .expandedStatusEnabled,
	.expandedSectionRow .expandedStatusDisabled,
	.expandedSectionRow .expandedStatusActiveNoSched,
	.expandedSectionRow .expandedStatusClosed {
		padding-left: 0 !important;
		margin-left: 0 !important;
	}
}
