.modalContent {
	padding: 2vmax;
}

.modalContainer {
	align-self: center;
}

.modalTitleContainer {
	gap: 5px;
	display: flex;
	justify-self: flex-start;
}

.title {
	font-size: 16px;
	font-weight: 400;
	font-family: 'Wavehaus-SemiBold', sans-serif;
	margin-top: 16px;
}

.scheduleFormContainer {
	display: flex;
	margin-top: 30px;
	margin-bottom: 31px;
	flex-direction: column;
}

.hint {
	display: block;
	color: #8b9095;
	margin-top: 4px;
	font-size: 10px;
	font-style: italic;
	font-family: 'Poppins-Light', sans-serif;
}

.emptyList {
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.emptyList i {
	width: 100%;
	text-align: center;
	color: #8b9095;
}

.statusIcon {
	height: 5px;
	width: 5px;
	border-radius: 100px;
	margin-top: 16px;
}

.alertContainer {
	gap: 16px;
	width: 100%;
	height: 40px;
	display: flex;
	font-size: 12px;
	padding: 0 14px;
	margin-bottom: 16px;
	border-radius: 5px;
	align-items: center;
	flex-direction: row;
	font-family: 'Poppins-Light', sans-serif;
	border: 1px solid #cccf19;
	background-color: #e5e78b;
}

.scheduleContainer {
	width: 100%;
	height: 350px;
	overflow-y: auto;
	overflow-x: hidden;
	margin-top: 10px;
	border-radius: 4px;
	padding: 0 8px 0 8px;
	border: 1px solid #dadce0;
}

.scheduleItem {
	width: 100%;
	height: 80px;
	display: flex;
	padding: 0 12px;
	border-radius: 4px;
	margin: 8px 16px 13px 0;
	justify-content: space-between;
}

.scheduleLabel {
	gap: 4px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.scheduleLabelTitle {
	font-size: 16px;
	font-family: var(--common-font-bold);
}

.scheduleLabelSubTitle {
	font-size: 12px;
}

.scheduleActions {
	gap: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.checkbox {
	margin: 8px 0 8px 0;
}

.disabled * {
	cursor: not-allowed !important;
}

.bold {
	font-family: var(--common-font-bold);
}

.toggle {
	margin-right: -8px;
}

.addIcon {
	vertical-align: middle;
}
