.serviceFeeContainer {
	margin-top: 10px;
}

.lineDeviderEnd {
	border: none;
	height: 2px;
	background-color: #dadce0;
}

.title {
	margin-top: -10px;
	margin-bottom: 15px;
	font-size: 1.2em;
}
.submitButton {
	margin-top: 2%;
	float: right;
}

.submit {
	width: 100% !important;
}
