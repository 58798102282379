.channelBranchAuditModal {
	padding: 0 10px 20px 10px;
}

.channelBranchAuditModalHeaderContainer {
	border: none;
}

.header {
	overflow-x: auto;
	overflow-y: hidden;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
	padding-bottom: 0;
}

.headerLeftPanel {
	float: left;
	padding-bottom: 0;
}

.headerRightPanel {
	float: right;
	padding-bottom: 0;
}

.headerTitle {
	text-align: left;
}

.tabs {
	overflow-y: hidden;
}

.tabs :global(.slds-tabs_default__link) {
	text-decoration: none !important;
}

.tabs :global(.slds-tabs_default__item.slds-is-active:after),
.tabs :global(.slds-tabs_default__item:hover:after) {
	background-color: var(--orange);
}

.tabs :global(.slds-disabled) {
	pointer-events: none;
}

.tabs :global(.slds-tabs_default__item) {
	height: 4vh;
	padding-left: 0.5vw;
	padding-right: 2.5vw;
	font-size: 0.9vw;
	padding-bottom: 35px;
}

.tabDisabled {
	color: gray;
	pointer-events: none;
	cursor: not-allowed !important;
}

.tabCheck {
	margin-left: 0.5vw;
	color: #28a745;
}

.content {
	overflow-y: auto;
	overflow-x: hidden;
	height: 40vh;
}

.bodyContent {
	font-size: 0.1vw;
	padding: 1vmax;
	height: 50vh;
}

.tabMaxheightAndWidth {
	height: 250px;
}

.placeholderTitle {
	font: normal normal 14px/21px var(--common-font-bold);
	color: #f26122;
	text-align: center;
	margin-top: 11px;
}

.placeholderSubTitle {
	color: #3b3b3b;
	font: normal normal 12px/18px var(--common-font);
	text-align: center;
	margin-top: 4px;
}

.placeholderImg {
	margin: 0 auto;
	display: block;
}

.tabsLabel {
	font-size: 2px;
}

.statusAuditLog {
	padding: 0;
	padding-left: 1.6vw;
	padding-right: 1.6vw;
}

b {
	font-size: 0.9vw;
}

.paperContainer {
	border-radius: 20px;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
}

.section {
	padding: 1vmax 0vmax !important;
}
