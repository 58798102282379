.select {
	font-family: var(--common-font);
	width: 100%;
}

.select > button {
	height: 100%;
	min-height: var(--input-min-height);
	color: #080707 !important;
	justify-content: space-between;
	padding: 0.3vmax !important;
	padding-left: 1vw !important;
	width: 100%;
	line-height: 3vh;
	font-size: var(--input-label-font-size);
	/* white-space: nowrap; */
}

.select.loading > button svg {
	animation: spin infinite linear 1000ms;
}

.select > button svg {
	width: 0.7vmax;
	height: 0.7vmax;
}

.select.error > button {
	border: 2px solid #ea001e;
}

.select.errorAdd > button {
	border: 2px solid #ea001e;
}

.select.empty > button {
	color: #706e6b !important;
}

.select.empty > button:disabled {
	cursor: not-allowed;
}

.select > div {
	min-width: 100% !important;
}

.selectItem > a:focus {
	color: var(--orange) !important;
	background-color: var(--lightBlue) !important;
	z-index: 10000000;
}
.selectItem:hover > a {
	color: var(--orange) !important;
	background-color: var(--lightBlue) !important;
	z-index: 10000000;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.helper {
	font-size: var(--input-label-font-size);
}

.dropdownContainer {
	color: black;
}

.fullWidth {
	width: 100%;
}

.disabledField {
	opacity: 0.6;
}
