.addButton svg {
	margin-right: 0.5vw;
	fill: var(--blue);
	width: 1vmax;
	height: 1vmax;
}

.addButton:disabled svg {
	fill: #c3c3c3;
}

.contactDetailsErrorCloseBtn {
	min-width: 40%;
}

.confirmTextName {
	font-size: 12px;
	font-weight: bold;
}
.confirmPartnerContactDetails {
	font-weight: bold;
	font-size: 13px;
}

.firstEntry {
	padding-top: 0;
}

.headerClass {
	font-size: 15px !important;
	font-weight: bold;
}

.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.successMessage {
	display: flex;
	height: 13vh;
	padding: 1.5vh 1vw 1vh 1vw;
}

.errorModal {
	width: 25vw !important;
}

.errorHeader {
	font-weight: bold;
	font-size: 13px !important;
	margin-bottom: 10px;
	/* width: 30vh; */
}
.errorMessage {
	font-size: 9pt !important;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
