.addButton svg {
	margin-right: 0.5vw;
	fill: var(--blue);
	width: 1vmax;
	height: 1vmax;
}

.addButton:disabled svg {
	fill: #c3c3c3;
}

.contactDetailsErrorCloseBtn {
	min-width: 40%;
}

.confirmTextName {
	font-weight: bold;
}
