.container {
	padding-top: 1vmax;
}

.container > * {
	margin-bottom: 2vh;
}

.sectionTitle {
	margin-bottom: 0;
}

.nameContainer {
	padding-top: 15px;
}
