.readonly {
	opacity: 0.7;
}

.mainContainer {
	width: 100% !important;
}

.searchContainer {
	margin-left: 25px;
}

.container {
	display: flex;
	justify-content: space-between;
	padding: 10px 0px 10px;
}

.column {
	width: 50%;
}

.entry {
	padding: 0.5em 0 0.5em 3em;
}
