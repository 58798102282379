.billerListContainer {
	border: 1px solid #DADCE0;
	border-radius: 10px;
	padding: 20px;
}

.listTitle {
	padding: 10px;
	font: normal normal 14px/13px var(--common-font-bold);
	letter-spacing: 0px;
	color: #36393D;
}

.dropdownButton {
    width: 100%;
		font-family: var(--common-font-light);
		font-size: var(--input-label-font-size);
}

.dropdownTriggerButton {
		font-family: var(--common-font);
    display: block;
    margin-top: 14px;
}

.searchBar {
	width: 100%;
}

.searchBar::placeholder {
	color: #8B9095;
}

.billerCardContainer {
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid #E9E9E9;
	cursor: pointer;
}

.billerCardContainer:hover {
	background: #F2F2F2 0% 0% no-repeat padding-box;
}

.billerScrollableContainer {
	overflow-y: auto;
	max-height: 400px;
	margin-top: 22px;
}

.tabs > ul {
	border: 0px;
	background-color: #FFFFFF;
	border-bottom: 2px solid #0176C0;
}

.tabMaxheightAndWidth {
	min-height: 550px;
}

.tabs > div {
	border: 0px;
	min-height: 550px;
}

.tabs > ul > li {
	background: #E9F5FD 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	margin-right: 15px;
	border-bottom: 1px solid #0176C0;
}

.tabs > ul > li > :global(a.slds-is-active) {
	border: 0px;
	color: #FFFFFF;
	background: #0176C0 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px !important;
}

.tabs > ul > li > a {
	text-decoration: none !important;
}

.tabs > ul > li > :global(a.slds-tabs_scoped__link:hover) {
	border: 0px;
}

.placeholderTitle {
	font: normal normal 14px/21px var(--common-font-bold);
	color: #F26122;
	text-align: center;
	margin-top: 11px;
}

.placeholderSubTitle {
	color: #3B3B3B;
	font: normal normal 12px/18px var(--common-font);
	text-align: center;
	margin-top: 4px;
}

.placeholderImg {
	margin: 0 auto;
	display: block;
}

.billerIsSelected {
	background: #F2F2F2 0% 0% no-repeat padding-box;
	font-weight: bold;
}

.billerIsSelected.isStatusActive {
	box-shadow: inset 0.25rem 0 0 #14BB02;
}

.billerIsSelected.isStatusEngaged {
	box-shadow: inset 0.25rem 0 0 #CCCF19;
}

.billerIsSelected.isStatusSuspended {
	box-shadow: inset 0.25rem 0 0 #D93025;
}

.billerIsSelected.isStatusDeactivated {
	box-shadow: inset 0.25rem 0 0 #8B9095;
}

.billerStatusIndactorActive {
	fill: #14BB02;
}

.billerStatusIndactorEngaged {
	fill: #CCCF19;
}

.billerStatusIndactorSuspended {
	fill: #D93025;
}

.billerStatusIndactorDeactivated {
	fill: #8B9095;
}

.toolTip > div {
	background: #F1F1F1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3B3B3B !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.toolTip > div > div {
	color: #3B3B3B !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.dropdownItem > ul > li > a:hover {
	color: var(--orange);
}

.dropdownInitState {
	color: #8B9095;
}

.dropdownActiveState {
	color: #3B3B3B;
}

.dropdownTriggerButton > button > svg {
	fill: #8B9095;
}
