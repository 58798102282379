.container {
	font-family: var(--common-font);
}

.title {
	font-family: var(--common-font-bold);
	font-size: 1.2vw;
	margin-bottom: 2vh;
}

.header {
	margin-bottom: 3.1vh;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
}

.row {
	margin-bottom: 2vh;
}

.action {
	color: var(--orange);
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
	display: inline-block;
}

.partnerAuditTrailTableContainer :global(.slds-card) {
	padding-left: 0;
	padding-right: 0;
}

.actionContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.statusContainer {
	display: flex;
	align-items: center;
	justify-content: left;
	margin-top: 5px;
}

.statusLabel {
	font-size: 0.8vw;
	text-align: left;
}

.statusField {
	margin-left: 10px;
	width: 125px;
}

.withPreHeader {
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	width: 100%;
	padding: 10px 16px;
	overflow-x: auto;
}