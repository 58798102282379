/* wrapped with Grid component */
.attachmentContainer {
	display: flex;
	overflow: hidden;
	overflow-x: auto;
	flex-flow: nowrap;
}

.incidentReportContainer {
	display: flex;
	flex-direction: column;
}

span.requestInformationLabel {
	width: 120px;
	font-weight: 600;
}

ul.requestInformationContainer {
	list-style: none;
	padding: 0;
	width: 25em;
}

li.requestInformationItem {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;
}

.reasonContainer {
	margin: auto;
	width: 92%;
	padding-top: 10px;
}

ul.voidRequestStatus {
	text-align: right;
	list-style: none;
	width: 25em;
}

ul.voidRequestStatus > li {
	display: flex;
	justify-content: space-between !important;
}

.uploadContainer {
	display: flex;
	flex-direction: column;
	gap: 5px;
	border: 1px dashed #dadce0;
	border-width: 2px;
	padding: 20px 15px;
	border-radius: 10px;
	text-align: center;
}

.styledHeading {
	margin: 0;
	margin-bottom: 12px;
	font-weight: 600;
	font-size: 14px !important;
}

/* WRAPPED UNDER DIALOG COMPONENT */
.styledDialog {
	width: 100%;
	border: 1px solid #3f3;
}

.header {
	font-size: 22px !important;
	font-weight: 600;
	color: #1e1e1e !important;
	text-align: left;
}

.headerStyle {
	border: none;
}

.modalContent {
	margin: 0px 8px 0px 10px;
}

.modalContentScrollable {
	padding: 0;
	overflow: hidden;
	max-height: 50em;
}

.modalContentScrollable:hover {
	overflow: auto;
}

.confirmCheckbox {
	padding-top: 10px;
	padding-bottom: 15px;
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 20px;
	display: inline-flex;
}

.confirmCheckbox > div:last-child {
	padding-left: 1.5vmax !important;
	font-size: 12px !important;
	text-align: left;
}

.card {
	border: 1px solid #dadce0;
	border-radius: 4px;
	padding: 15px 10px;
}

/* WRAPPED UNDER CARD COMPONENT */
.reasonWrapper {
	font-size: 14px !important;
}

.approve {
	color: #14bb02;
	background: #ffffff;
	border: 1px solid #14bb02;
	border-radius: 4px;
	opacity: 1;
	font-size: 14px !important;
	width: 120px;
	min-height: 42px;
	display: flex;
	gap: 5px;
	justify-content: space-evenly;
}

.decline {
	color: #d93025;
	background: #ffffff;
	border: 1px solid #d93025;
	border-radius: 4px;
	opacity: 1;
	font-size: 14px !important;
	width: 120px;
	min-height: 42px;
	display: flex;
	gap: 5px;
	justify-content: space-evenly;
}

.approveIconDisabled  path, .rejectIconDisabled path { 
	fill:#c4c4c4; 
}

.decline:disabled,
.decline:disabled:hover,
.approve:disabled,
.approve:disabled:hover {
	border: 1px solid #c4c4c4;
	border-radius: 4px;
	background: white;
	cursor: not-allowed !important;
	color: #c4c4c4;
}

.approve:hover,
.approve:active,
.approve:focus {
	color: #ffffff;
	background: green;
	border: 1px solid green;
}

.approveIcon,
.rejectIcon {
	height: 16px;
	width: 20px;
}

.decline:hover,
.decline:active,
.decline:focus {
	color: #ffffff;
	background: red;
	border: 1px solid red;
}

.footerContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: end;
	gap: 10px;
	margin: 2px 26px 2px 0px;
}

.dividerTop {
	margin-top: 5px;
}

.dividerBottom {
	margin-bottom: 5px;
}

.textAreaLabel {
	font-size: 12px !important;
}

.incidentReportContainer :global(.slds-textarea) {
	font-size: 12px !important;
	height: 90px !important;
	background: var(--unnamed-color-ffffff);
}

.inputFields {
	margin-bottom: 30px !important;
}

.checked :global(.slds-checkbox_faux) {
	background-color: #f6a07b !important;
	padding: 1px !important;
	border-radius: 3px !important;
}

.checked :global(.slds-checkbox_faux::after) {
	border-bottom: 2px solid #ffffff !important;
	border-left: 2px solid #ffffff !important;
}

.checked :global(.slds-form-element__label) {
	color: #0076c0 !important;
	padding-left: 50px !important;
}

.infoHeading {
	font-weight: bold;
	margin-bottom: 18px;
}
.infoContactNumber {
	width: 296px;
	height: 42px !important;
}
.infoRequestor {
	width: 296px;
	height: 42px !important;
}

.informationSection {
	padding-top: 0;
}

.informationData {
	display: flex;
	font-size: 12px;
	color: #3b3b3b;
}

.informationData > span {
	margin-right: 30px;
}

.informationLabel {
	font-size: 12px;
	font-weight: 600;
}

.subHeaderLabel {
	margin-left: 320px;
	text-align: left;
}

.statusBadge {
	align-items: left;
}

.fieldStyle {
	width: 270px;
	height: 42px !important;
}

.fieldStyle:disabled,
.fieldStyle::placeholder {
	color: #c4c4c4;
}

.modalContainter {
	width: 687px;
	height: 100vh;
}

.modalContainter > footer {
	border: 0;
}

.subHeader {
	margin: 0;
	font-weight: 600;
	font-size: 14px !important;
}

.section {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.checkedBox {
	flex-shrink: 0;
}

.checkedBox :global(.slds-checkbox_faux) {
	background-color: #ffffff !important;
}

.isChecked :global(.slds-checkbox_faux) {
	background-color: #f6a07b !important;
	padding: 1px !important;
	border-radius: 3px !important;
}

.isChecked :global(.slds-checkbox_faux::after) {
	border-bottom: 2px solid #ffffff !important;
	border-left: 2px solid #ffffff !important;
}

.checkedBoxContainer {
	margin-top: 15px !important;
	display: inline-flex !important;
	align-items: center;
}

.checkedBoxContainer > div {
	padding-left: 0.1vmax !important;
	font-size: 12px !important;
	letter-spacing: 0px !important;
	color: #3b3b3b !important;
	opacity: 1 !important;
}

.checkedBoxContainer :global(.slds-checkbox),
.checkedBoxContainer :global(.slds-checkbox__label) {
	display: flex;
	align-items: center;
}

.uploadAttachmentHeader {
	font-size: 11px !important;
	color: #3b3b3b !important;
	font-weight: 600 !important;
	margin-top: 8px !important;
	margin-bottom: 8px !important;
}

.textHelper {
	margin-top: 5px !important;
}

.textHelperLabel {
	display: flex;
	font-size: 10px !important;
}

.textHelperLabel > p {
	font-size: 10px !important;
	font-style: italic !important;
	color: #8b9095 !important;
	font-family: var(--common-font) !important;
}

.textHelperLabel > span {
	font-size: 10px !important;
	padding-left: 5px !important;
	font-weight: 600 !important;
}

.gridContainer {
	margin: 0 !important;
	text-align: left;
}

.gridContainer > span {
	text-align: right !important;
	font-size: 12px !important;
	letter-spacing: 0px !important;
	color: #3b3b3b;
	opacity: 1 !important;
	width: 100 !important;
}

.referenceId {
	font-weight: 600 !important;
	color: #0076c0 !important;
	margin-left: 10px;
	font-size: 14px !important;
}

.sectionRowContainer {
	display: flex !important;
	align-items: center !important;
	margin: 0 0 10px 0 !important;
}

.sectionContainerHeader {
	padding: 10px 0 0 0 !important;
}

.sectionContainerSubHeader {
	display: flex !important;
	justify-content: flex-end !important;
	padding: 0 !important;
}

.status {
	padding: 3px 13px !important;
}

.searchBar {
	height: 35px;
}

.sectionLabel {
	font-weight: normal;
	font-size: 12px;
	margin-bottom: 1.5vh;
}

.sectionSpacing {
	padding-top: 0;
}

.parentSectionTitle {
	font-size: 14px;
	font-weight: 600;
	text-align: left;
}

.largeSectionTitle {
	margin-top: 0px;
	font-size: 14px;
	margin-bottom: 2vh;
	font-weight: 600;
}

.dragAndDropText {
	color: #8b9095;
	font-style: italic;
	font-size: 12px;
	font-family: 'Poppins-Regular', sans-serif;
}

span.outline {
	color: #f6a07b;
	background: #ffffff;
	border: 1px solid #f6a07b;
	border-radius: 4px;
	opacity: 1;
	padding: 8px 25px;
	width: 140px;
	height: 45px;
	font-size: 21px;
	font-family: 'Poppins-Regular', sans-serif;
}

.previewPlaceholder {
	height: 95%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #dadce0;
}

.previewFile {
	margin: 0 auto;
	width: 100%;
}

.previewImage {
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 95%;
	height: 35vw;
}

.othersInput {
	resize: none !important;
	width: 100%;
	overflow: hidden;
	padding: 10px;
	font-size: 12px !important;
	background-color: #fff;
	color: #c4c4c4;
}

.horizontalLine {
	position: relative;
	width: 640px;
	left: -16px;
	bottom: 10px;
	margin: 0;
}

.imagePreview {
	max-width: 100%;
	max-height: 90%;
}