.actionContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.action {
	color: var(--orange);
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
	display: inline-block;
}

.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
}

.errorHeader {
	margin-bottom: 10px;
	font: normal normal 1.2vmax 'Poppins-Light', sans-serif;
}

.errorBody {
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.errorFooter {
	margin-top: 0;
	margin-bottom: 30px;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.tabPanel > div {
	padding-top: 0 !important;
	min-height: auto;
}
