button.outline {
	color: #f26122;
	background: #ffffff;
	border: 1px solid #f26122;
	border-radius: 4px;
	opacity: 1;
	font-size: 14px;
	padding: 0 0.6vw;
	white-space: nowrap;
}

button.outline2 {
	color: #f26122;
	background: #ffffff;
	border: 1px solid #f26122;
	border-radius: 4px;
	opacity: 1;
	font-size: 14px;
}

button.outline:hover,
button.outline:active,
button.outline:focus {
	color: #ffffff;
	background: #f26122;
	border: 1px solid #f26122;
}

button.outline:disabled {
	color: #f6a07b;
	background: #ffffff;
	border: 1px solid #f6a07b;
}

button.outline2:hover,
button.outline2:active,
button.outline2:focus {
	color: #ffffff;
	background: #f26122;
	border: 1px solid #f26122;
}

button.outline2:disabled {
	color: #f6a07b;
	background: #ffffff;
	border: 1px solid #f6a07b;
}
