.time {
	border: 1px solid #dadce0;
	border-radius: 5px;
	padding: 1em 0 !important;
	font-family: var(--common-font);
	width: 100%;
}

.timeContainer {
	padding: 0 1em;
}

.time > * {
	padding: 0.5em 0;
}
