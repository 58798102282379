.placeholderTitle {
	font: normal normal 14px/21px var(--common-font-bold);
	color: #F26122;
	text-align: center;
	margin-top: 11px;
}

.placeholderSubTitle {
	color: #3B3B3B;
	font: normal normal 12px/18px var(--common-font);
	text-align: center;
	margin-top: 4px;
}

.placeholderImg {
	margin: 0 auto;
	display: block;
}

.tabMaxheightAndWidth {
	min-height: 550px;
}
