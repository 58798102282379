.container {
	padding-top: 1vmax;
}

.container > * {
	margin-bottom: 2vh;
}

.avatar {
	border-radius: 50%;
	background-color: #99d7ff;
	width: 40%;
	height: 100%;
	max-width: 6vw;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2.5vw;
	color: white;
	position: relative;
}

.uploadBtn {
	background-color: #0176c0;
	display: flex;
	justify-content: center;
	width: 2.2vmax;
	height: 2.2vmax;
	font-size: 1.2vw !important;
	border-radius: 50%;
	position: absolute;
	left: 4vw;
	top: 7vh;
	max-height: none;
}

.uploadBtn svg {
	color: white;
	fill: white;
	stroke: white;
}

.avatar .image {
	width: 70%;
}

.hasImage {
	background-color: transparent;
	border: 1px solid #c8c8c8;
}

.logoFieldContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 12vh;
}

.logoLabelContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-left: 2vw;
}

.logoLabel {
	font-size: 0.7vw;
	margin-bottom: 1vh;
}

.logoSubtext {
	color: #8b9095;
	font-size: 0.6vw;
}

.logoSubtextValue {
	color: black;
	font-weight: bold;
	margin-left: 0.2vw;
}

.selectWithOthersContainer {
	padding: 0;
}

.selectWithOthersContainer > *:first-child {
	padding-left: 0;
}
.selectWithOthersContainer > *:last-child {
	padding-right: 0;
}

.otherIndustry > *:first-child {
	padding-left: 0;
}

.otherIndustry > *:last-child {
	padding-right: 0;
}

.othersTextField {
	display: flex;
	align-items: flex-end;
}

.otherLabel {
	color: transparent;
	opacity: 0;
}

.otherLabel * {
	pointer-events: none;
}

.inputTime {
	background: transparent;
	border-radius: 4px;
	border-color: #dedcdb;
	border-style: solid;
	border-width: 1px;
	width: 262px;
	height: 26px;
	text-transform: uppercase;
	padding: 0.3vmax;
}

.inputTime:focus {
	outline: 2px solid #92cdff; /* oranges! yey */
}

.grid {
	height: 69px;
}

.rcTimer {
	width: 100%;
	position: relative;
	display: inline-block;
	padding: 4px 7px;
	height: 28px;
	cursor: text;
	font-size: 12px;
	line-height: 1.5;
	color: #666;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	-webkit-transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rcTimerPopup {
	z-index: 1070;
	width: 170px;
	position: absolute;
	box-sizing: border-box;
}
