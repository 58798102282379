.divider {
	border-top: 0.5vh solid #0176c0;
	margin: 0 1vmax;
}

.label {
	margin-bottom: 1vh;
	display: flex;
	font-size: 13px;
	margin-left: 10px;
	font-family: var(--common-font);
}

.gbl_fee {
	margin-bottom: 1vh;
	font-size: 13px;
	padding: 15px;
	font-family: var(--common-font);
}
