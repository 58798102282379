.iconBtn {
	width: 1vw;
	height: 1vw;
}

.viewIcon,
.viewIcon:focus {
	color: var(--orange);
}
.viewIcon:hover {
	color: var(--darkerOrange);
}

.viewIcon {
	justify-content: left;
}
.tooltip {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.tooltip > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.labelActive {
	color: #14bb02;
}

.labelInactive {
	color: #c84031;
}

.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
}

.errorHeader {
	margin-bottom: 10px;
	font: normal normal 1.2vmax 'Poppins-Light', sans-serif;
}

.errorBody {
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.errorFooter {
	margin-top: 0;
	margin-bottom: 30px;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.label {
	text-align: left;
	padding-left: 10px;
}

.partnerIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 39px;
	height: 39px;
	background: var(--unnamed-color-f26122) 0% 0% no-repeat padding-box;
	background: #f26122 0% 0% no-repeat padding-box;
	border: 1px solid #00000000;
	border-radius: 16px;
	opacity: 1;
}

.brandIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 39px;
	height: 39px;
	background: var(--unnamed-color-c9cb2a) 0% 0% no-repeat padding-box;
	background: #c9cb2a 0% 0% no-repeat padding-box;
	border: 1px solid #00000000;
	border-radius: 16px;
	opacity: 1;
}

.moduleIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 39px;
	height: 39px;
	background: var(--unnamed-color-0176c0) 0% 0% no-repeat padding-box;
	background: #0176c0 0% 0% no-repeat padding-box;
	border: 1px solid #00000000;
	border-radius: 16px;
	opacity: 1;
}

.tableContainer {
	margin-top: 20px;
	padding: 1vw 0.75vw 1vw;
}

.summaryCards {
	padding: 0.4vw !important;
}

.summaryCards > div[class]:first-child > div[class] > div[class] {
	padding: 0.4vw !important;
}

.summaryCards > div[class]:last-child {
	font-size: 0.8vw !important;
	padding-right: 0.8vw !important;
}

.summaryCards
	> div[class]:first-child
	> div[class]
	> div[class]
	> div[class]:first-child {
	font-size: 0.8vw !important;
}

.summaryCards
	> div[class]:first-child
	> div[class]
	> div[class]
	> div[class]:last-child {
	margin-top: 0.1vw !important;
}

.summaryCards
	> div[class]:first-child
	> div[class]
	> div[class]
	> div[class]:last-child
	> div[class]:last-child {
	font-size: 1.5vw !important;
	padding-left: 0.5vw !important;
	padding-top: 0.5vw !important;
}
