.successModal {
	align-self: center;
	border-radius: 0.25rem;
	width: fit-content;
	width: 23vw;
}
.larger {
	width: 23vw;
}
.successModalContainer {
	align-self: center;
	top: -10vh;
	padding: 4vw;
}
.successModalContent {
	padding: 5vh 0 0.5vh 0;
}
.successIcon {
	fill: green;
	width: 4vw;
}
.successModalIconContainer {
	display: flex;
	justify-content: center;
}
.successModalBody > div {
	font-family: var(--common-font);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	margin: 1em 0;
}
.successModalActions > div {
	display: flex;
	justify-content: center;
}
.successModalHeader button {
	display: none;
}
.successModalText {
	font-size: 16px;
	margin-top: 1vh;
	/* margin-bottom: 1vh;
	padding-left: 3vh;
	padding-right: 3vh; */
}
.successModalSubtext {
	width: 70%;
	font-size: 0.7vw;
	margin-bottom: 2vh;
}
.successModalSubtextBold:before {
	content: '\00a0';
}
.successModalSubtextBold {
	display: inline;
	font-weight: bold;
}
.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}
.successBody {
	font-size: 0.8vmax;
	margin-bottom: 30px;
}
.successBodyWithPadding {
	padding-left: 1.5vmax;
	padding-right: 1.5vmax;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}
.successModalBtn {
	margin-bottom: 3vw;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
