.select :global(.slds-button) {
	/* border: none; */
	font-size: 0.6vw !important;
	line-height: 0 !important;
	border-radius: 16px;
}

.option :global(.slds-button) {
	color: white !important;
	padding: 0.5vh 0.4vw !important;
	line-height: 0;
	min-height: 0;
}

.option :global(.slds-button:disabled) {
	opacity: 0.6;
}

.option {
	font-size: 0.8vw;
}

.engaged :global(.slds-button) {
	background-color: #cccf19;
}

.active :global(.slds-button) {
	background-color: #14bb02;
}

.suspended :global(.slds-button) {
	background-color: #d93025;
}

.deactivated :global(.slds-button) {
	background-color: #8b9095;
}

.engaged a {
	color: #cccf19;
}

.active a {
	color: #14bb02;
}

.suspended a {
	color: #d93025;
}

.deactivated a {
	color: #8b9095;
}
