.header {
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 10px;
	background-color: var(--blue);
	margin-right: 10px;
	margin-top: 5px;
	color: white;
}

.relativeContainer {
	position: relative;
	height: 35vh;
}

.body {
	border-right: 1px solid #dadce0;
	border-left: 1px solid #dadce0;
	margin-right: 10px;
	max-height: 35vh !important;
	min-height: 2vh;
	overflow-y: auto;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.footer {
	border: 1px solid #dadce0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	padding: 10px;
	margin-right: 10px;
	text-align: right;
}

.action {
	float: right;
	display: inline;
}

.lineDevider {
	margin-top: -1vh;
	margin-bottom: 30px;
	height: 2px;
	background-color: #dadce0;
	border: none;
}

.expandedContent {
	margin-top: -24px;
	max-height: 300px;
	overflow-y: auto;
}

.noItem {
	padding-bottom: 2vh;
}

.accordionMessage {
	text-align: center !important;
	padding-top: 10px;
	padding-bottom: 100%;
	font-weight: bold;
}

.successMessage {
	display: flex;
	height: 9vh;
	padding-top: 3vh;
}

.subTextNext b {
	font-size: 1em !important;
}

.contactDetailsErrorCloseBtn {
	width: 13.5vh !important;
}

.alertBody {
	margin-top: 4vh !important;
}
