.label {
	margin-bottom: 1vh;
	display: flex;
	font-size: 11px;
}

.required::after {
	color: #d93025;
	content: '*';
	margin-left: 0.1vw;
}

.selectContainer > * {
	padding-left: 0.5vw;
	padding-right: 0.5vw;
}

.selectContainer > *:first-child {
	padding-left: 0;
	padding-right: 1vw;
}

.selectContainer > *:last-child {
	padding-left: 1vw;
	padding-right: 0;
}

.input :global(.slds-button[disabled]) {
	cursor: not-allowed;
}
.helper {
	font-size: var(--input-label-font-size);
}
