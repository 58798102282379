.modal {
	font-family: var(--common-font);
	overflow-y: hidden;
	/* font-size: 12px; */
}

.tabs {
	/* overflow-y: hidden; */
	height: 100%;
	padding: 0 12px 0 12px;
}

.tabs :global(.slds-tabs_default__link) {
	text-decoration: none !important;
}

.tabs :global(.slds-tabs_default__item.slds-is-active:after),
.tabs :global(.slds-tabs_default__item:hover:after) {
	background-color: var(--orange);
}

.tabs :global(.slds-disabled) {
	pointer-events: none;
}

.tabs :global(.slds-tabs_default__item) {
	height: 4.5vh;
	padding-left: 0.5vw;
	padding-right: 0.5vw;
	font-size: 1vw;
	padding-bottom: 30px;
}

.tabs :global(.slds-tabs_default__content) {
	height: 100%;
}

.body {
	/* padding-left: 1vmax;
    padding-right: 1vmax; */
	overflow-y: hidden;
	height: 400px;
}

.editButton {
	border-width: 0px;
	float: right;
	color: orange;
	font: bold normal 12px/15px 'Poppins-Regular', sans-serif;
}

.editButton svg {
	margin-right: 0.5vw;
	fill: var(--orange);
	width: 1vmax;
	height: 1vmax;
	border-width: 0px;
	color: blue;
}

.editButton:disabled svg {
	fill: #c3c3c3;
}

/* .header {
    
} */

.headerItems {
	font: normal normal 11px 'Poppins-Regular', sans-serif;
	display: inline-block;
	/* width: 130px; */
	font-weight: 600;
}

.colon {
	padding-right: 10px;
}

.titleActionBtnContainer {
	display: block;
	display: flex;
	justify-content: right;
	min-height: 1.8vw;
}

/* .header {
    overflow: auto;
    overflow-y: hidden;
    padding-top: 1vmax;
    padding-left: 0.5vmax;
    padding-right: 0.5vmax;
    font: bold normal 11px/20px Poppins-Regular;
    display: inline-block;
    width: 125px;
} */

.headerH1 {
	font-size: 1.25rem;
	margin-left: 0 !important;
}

.header {
	font: normal normal 12px 'Poppins-Regular', sans-serif;
	display: inline-block;
	/* width: 150px; */
}

.headerInfo {
	/* font: bold bold 1px Poppins-Regular; */
	font-weight: 600;
	display: inline-block;
}

.titleTextContainer {
	font-size: 1.4vw;
	text-align: left;
}

.detailsTextContainer {
	font-size: 12px;
	text-align: left;
	padding: 0;
}

.titleRightPanel {
	float: right;
	display: flex;
	justify-content: right;
	flex-direction: column;
	padding-top: 1vh;
}

.titleLeftPanel {
	float: left;
	display: flex;
	justify-content: left;
	flex-direction: column;
	padding-top: 2vh;
}

.btn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.successDoneBtn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin-bottom: 20px;
}

.btn:hover .btnIcon :global(path),
.btn:active .btnIcon :global(path),
.btn:focus .btnIcon :global(path) {
	fill: #ffffff;
}

.btnIcon {
	width: 15px;
	height: 20px;
	margin-right: 4px;
	margin-left: 4px;
}

.headerContainer {
	border: none;
	padding: 16px 24px 16px 24px;
	background-color: #fff !important;
}

.saveModalContainer,
.approvalModalContainer,
.rejectionModalContainer {
	width: 400px !important;
}

.remarksMultiline {
	margin-top: 2vh;
	margin-left: -10px;
	margin-right: -10px;
	text-align: left !important;
	height: 100px !important;
}

.content {
	overflow-y: hidden;
	height: 400px;
	/* block-size: fit-content; */
	padding: 0 12px 5vmax 12px;
	overflow-x: hidden;
	align-items: center;
}

.approveHeading {
	top: 250px;
	left: 602px;
	font-size: 14px;
	font-weight: 500;
	font-stretch: condensed;
}

.updateStatus {
	justify-content: left;
}

.updateStatusBody1 {
	text-align: center;
	margin-bottom: 20px;
}

.exitConfirmationBody {
	text-align: center;
	padding: 20px 50px 20px 50px;
}

.updateStatusBody2 {
	text-align: left;
}

.updateRemarks {
	resize: none;
	height: 120px;
	width: 36rem;
}

.confirmButton {
	height: 50px;
}

.successHeader {
	margin-bottom: 2vh;
	font-size: 1vw;
	padding-top: 1vw;
}

.headerText {
	font-size: 14px !important;
}

.updateStatusRemarks {
	height: 200px;
}

.errorHeader {
	font-size: 1vmax;
	margin-bottom: 10px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

/* .errorBody {
	font-size: 0.8vmax;
	padding-left: 10px;
	padding-right: 10px;
} */

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.successBody {
	font-size: 1vmax;
	margin-bottom: 10px;
}

.updateStatusSubLabel {
	font-size: 11px;
	font-family: 'Poppins-Regular', sans-serif;
	color: #8b9095 !important;
	text-align: left;
}

.editReportBody2 {
	text-align: left;
}

.vspaced > * {
	margin-bottom: 1vh;
}

.editReportBody1 {
	padding: 2vh 0 2vh 0;
}
