.header {
	padding: 0.5rem;
	justify-content: flex-end;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.ul {
	margin-left: 5px;
}

.nametag {
	font-family: var(--common-font);
	font-size: 0.8vw;
	color: #ffffff;
	padding: 0 1em 0 1em;
}

.avatar {
	width: 2vw;
}
