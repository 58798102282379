.tabs > ul {
	border: 0px;
	background-color: #ffffff;
	border-bottom: 2px solid #0176c0;
}

.tabMaxheightAndWidth {
	min-height: 550px;
}

.tabs > div {
	border: 0px;
	min-height: 550px;
	padding-left: 0;
	padding-right: 0;
}

.tabs > ul > li {
	background: #e9f5fd 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px;
	margin-right: 15px;
	border-bottom: 1px solid #0176c0;
}

.tabs > ul > li > :global(a.slds-is-active) {
	border: 0px;
	color: #ffffff;
	background: #0176c0 0% 0% no-repeat padding-box;
	border-radius: 10px 10px 0px 0px !important;
}

.tabs > ul > li > a {
	text-decoration: none !important;
}

.tabs > ul > li > :global(a.slds-tabs_scoped__link:hover) {
	border: 0px;
}


.rowBreak {
	word-break: break-all;
}

.errorBody {
	font-size: 0.8vmax;
}

.actionCell button {
	width: min(1vmax, 20px);
	height: min(1vmax, 20px);
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}

.crossIcon,
.crossIcon:focus {
	color: var(--orange);
}

.crossIcon:hover {
	color: var(--darkerOrange);
}

.toggleButtonContainer {
	padding: 0 15px 0 0;
}

.toggleButtonContainer :global(.slds-checkbox_faux:after) {
	width: 0.7rem;
	height: 0.7rem;
	border: none !important;
}

.toggleButtonContainer :global(.slds-checkbox_faux:before) {
	left: 1rem !important;
	width: 0.7rem !important;
	height: 0.7rem !important;
}

.toggleButtonContainer:global(.slds-checkbox_toggle [type='checkbox']:checked) {
	display: none;
}

.toggleButtonContainer :global(.slds-checkbox_faux) {
	width: 30px;
	height: 15px;
}

.toggleButtonContainer:global(
		.slds-checkbox_toggle
			[type='checkbox'][disabled]
			+ .slds-checkbox_faux_container
			.slds-checkbox_faux:after
	) {
	background-color: #cbcbcb;
}
