.navigation {
	padding-left: 5vh;
	padding-right: 5vh;
}

.tabButton {
	padding: 10px;
	margin-top: -20px;
	text-decoration: none !important;
}

.tabs {
	display: flex;
	flex-wrap: wrap;
	padding-left: 5vh;
	padding-right: 5vh;
}

.tabsLabel {
	padding: 3px 16px;
	cursor: pointer;
	border-bottom: 1px solid rgb(172, 172, 172);
	font-size: 12px !important;
}

.tabsLabel:hover {
	border-bottom: 2px solid #f26112;
	z-index: 2;
}

.tabsRadio {
	display: none;
}

.tabsContent {
	order: 1;
	width: 100%;
	line-height: 1.5;
	font-size: 0.9em;
	display: none;
}

.tabsRadio:checked + .tabsLabel {
	font-weight: bold;
	border-bottom: 3px solid #f26112;
	z-index: 2;
}

.tabsRadio:checked + .tabsLabel + .tabsContent {
	display: initial;
}

.labelWrapper {
	position: relative;
}

.tabs::after {
	content: '';
	position: sticky;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 1px;
	margin-top: -1px;
	background-color: rgb(172, 172, 172);
}
