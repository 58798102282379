.modalButton {
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #0176c0;
	color: #0176c0;
	font-size: 0.9rem;
}

.modalButton:hover,
.modalButton:active,
.modalButton:focus {
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #0176c0;
	color: #0176c0;
	box-shadow: #ffffff;
}

.modalButton.fullWidth {
	width: 100%;
}

.modalItem {
	display: flex;
}
