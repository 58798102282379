.addButton svg {
	margin-right: 0.5vw;
	fill: var(--blue);

	width: 1vmax;
	height: 1vmax;
}

.addButton:disabled svg {
	fill: #c3c3c3;
}

.contactDetailsErrorCloseBtn {
	min-width: 40%;
}

.confirmTextName {
	font-weight: bold;
}

.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.headerClass {
	font-size: 15px !important;
	font-weight: bold;
}
