.confirmationModal {
}

.confirmationModalContainer {
}

.confirmationModalHeaderContainer {
}

.confirmationModalBodyHeader {
	padding: 45px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-family: 'Poppins-Regular', sans-serif;
	flex-direction: column;
	white-space: pre-line;
}

.confirmationModalBodyContent {
	padding: 0 15px 15px 15px;
	display: flex;
	flex-direction: column;
}

.confirmationModalBodyContent :global(.slds-form-element__label) {
	font-size: 11px !important;
	font-family: 'Poppins-Regular', sans-serif;
	font-weight: 400 !important;
}

.confirmationModalFooter {
	padding-top: 5px;
	padding-bottom: 5px;
}

.confirmationModalBtn {
	padding: 20px 20px !important;
	min-width: 120px;
	height: 20px;
	font-size: 13px !important;
}
