.container {
	padding-top: 1vmax;
}

.container > * {
	margin-bottom: 2vh;
}

.selectAll {
	margin-bottom: 1em;
}
