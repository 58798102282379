.container {
	padding-top: 1vmax;
}

.container > * {
	margin-bottom: 2vh;
}

.addButton,
.addButton:hover:not(:disabled),
.addButton:focus,
.addButton:active:not(:disabled) { 
	font-weight: bold;
	color: #f26122;
	width: fit-content;
	margin-right: 2vh;
}

.addButtoContainer {
	display: flex;
	align-items: center;
	position: relative;
}

.limitMsg {
	color: #f26122;
	font-size: x-small;
}

.deletePenaltyButton {
	top: 12px;
}

.deletePenaltyButton:hover path,
.deletePenaltyButton:focus path,
.deletePenaltyButton:active path {
	color: #e1571c;
	fill: #e1571c;
}

.penaltiesContainer {
	margin-bottom: 15px;
	position: relative;
	display: flex;
	align-items: center;
}