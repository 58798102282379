.italic {
	font-style: italic;
}
.bold {
	font-weight: bold;
}
.modal {
	font-family: var(--common-font);
	overflow-y: hidden;
}
.headerContainer {
	border: none;
}

.btn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.btnApprove,
.btnReject {
	background-color: #ffffff;
}

.btnApprove,
.btnApprove:focus,
.btnApprove:active,
.btnApprove:hover {
	color: #14bb02;
	border: 1px solid #14bb02;
}

.btnReject,
.btnReject:focus,
.btnReject:active,
.btnReject:hover {
	color: #d93025;
	border: 1px solid #d93025;
}

.btnApproveIcon,
.btnRejectIcon {
	margin-right: 10px;
}

.edit {
	display: none;
}

.tableHeader{
	color: white;
	background-color: var(--blue);
}

.tableBody {
	
}

.table {
	width: 100%;
	border-radius: 10px;
	overflow: hidden ;
}

.statusPill {
	min-width: 100px;
	margin-left: 10px;
}

.tableTitle {
	padding-bottom: 20px;
	font-size: 1vw;
	font-family: var(--common-font-bold);
	display: block;
}

.displayNone{
	display: none;
}