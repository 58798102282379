.searchBar {
	width: 100%;
}

.downloadButton {
	height: 100%;
}

.statusPending {
	color: #f26122;
}
.statusApproved {
	color: #14bb02;
}
.statusDeclined {
	color: #d93025;
}

.innerButtons {
	padding-top: 1.9em;
}

.actionCell button {
	width: 1vmax;
	height: 1vmax;
}
.iconBtn {
	width: 1vmax;
	height: 1vmax;
}
.checkIcon {
	fill: #14bb02;
}

.crossIcon {
	fill: red;
}

.errorBody {
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 1em;
	font-size: 0.7vw;
	color: #3e3f42;
}

/* .filters > div,
.innerButtons > div {
	padding-left: 0.2em;
	padding-right: 0.2em;
} */

.serviceTypeContainer {
	min-width: 20%;
}

.datePickerContainer {
	min-width: 15%;
}

.clearFilterBtnContainer {
	/* min-width: 60%; */
}

.retryBtn {
	max-width: 30%;
}

.cellBreak {
	word-break: break-all;
}

.successDoneBtn {
	width: 40%;
}
.material-symbols-outlined {
	font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}
button {
	background-color: transparent;
	color: var(--gh-blue-5);
	border: none;
	cursor: pointer;
	position: relative;
}
.expanded {
	min-height: 35vh;
	max-height: 100%;
	padding-left: 3.1vw;
	margin-bottom: -1vh;
	margin-left: -1.5vh;
	margin-top: 100%;
	width: 105vw;
	background-color: #f8f8f9;
	border-top: 1px solid #dadfe2;
	position: relative;
}
.check {
	color: #14bb02;
	padding-left: 48vw;
}
.ex {
	color: #d93025;
	margin-top: -4vh;
	padding-left: 41.5vw;
}
.actionbutton {
	padding-top: 2vh;
	float: right;
	display: flex;
}
.approve {
	color: green;
	background: #ffffff;
	border: 1px solid green;
	border-radius: 4px;
	opacity: 1;
	font-size: 14px;
	margin-left: 1vw;
	margin-right: 1vw;
	width: 5vw;
	padding-left: 5px;
}
.decline {
	color: red;
	background: #ffffff;
	border: 1px solid red;
	border-radius: 4px;
	opacity: 1;
	font-size: 14px;
	width: 4.5vw;
	margin-right: 10px;
	padding-left: 5px;
}
.approve:hover,
.approve:active,
.approve:focus {
	color: #ffffff;
	background: green;
	border: 1px solid green;
}

.decline:hover,
.decline:active,
.decline:focus {
	color: #ffffff;
	background: red;
	border: 1px solid red;
}
.emptyattachment {
	margin-left: 46vw;
	width: 72px;
	height: 66px;
}
.noattachment {
	padding-left: 44vw;
}
.attachmentButton {
	background-color: #ffffff;
	border-radius: 30px;
	padding: 15px;
	border: solid #c4c4c4 1px;
	margin-right: 10px;
	margin-bottom: 2vh;
}
.attachmentCount {
	text-align: left;
	font: italic normal normal 12px/46px 'Poppins', sans-serif;
	letter-spacing: 0px;
	color: #8b9095;
	opacity: 1;
	margin-top: -10px;
	margin-bottom: -30px;
}
.pngIcon {
	height: 2vh;
	margin-right: 5px;
}
.jpgIcon {
	height: 2vh;
	margin-right: 5px;
}
.jpegIcon {
	height: 2vh;
	margin-right: 5px;
}
.pdfIcon {
	height: 2vh;
	margin-right: 5px;
}
.txtIcon {
	height: 2vh;
	margin-right: 5px;
}
.attachment {
	margin-right: 30vw;
}
button {
	background-color: transparent;
	color: var(--gh-blue-5);
	border: none;
	cursor: pointer;
	position: relative;
	padding-top: 50%;
}
