.fileUploadTable {
	width: 100%;
}

.fileUploadTableContainer {
	border: 1px solid #dadce0;
	border-radius: 10px;
	overflow-x: hidden;
	width: 100%;
}

.fileUploadTableBody {
	padding-left: 0 !important;
	padding-right: 0 !important;
	width: 100%;
}

.fileUploadTableLabelContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-left: 20px;
	margin-bottom: 20px;
}

.fileUploadTableLabelSubtext {
	color: #8b9095;
	font-size: 0.9vw;
}

.fileUploadTableLabelSubtextValue {
	color: black;
	margin-left: 0.2vw;
	font-size: 0.8vw;
}

.fileUploadTableHeader {
	font-size: 12px;
	color: #ffffff;
	background-color: #0176c0;
	/* text-align: center; */
	display: flex;
	align-items: center;
}

.fileUploadTableHeaderItem {
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 0;
	padding-right: 0;
}

.fileUploadTableHeaderItem.action {
	text-align: right;
}

.fileUploadTableHeaderItem:first-child {
	padding-left: 40px;
}

.fileUploadTableHeaderItem:last-child {
	padding-right: 40px;
}

.fileUploadTableRow {
	font-size: 12px;
	line-height: 2vh;
	background-color: #ffffff;
	border: 1px solid transparent;
}

.fileUploadTableRow.error {
	border-color: #ea001e;
}

.fileUploadTableRow:nth-child(odd) {
	background-color: #f3faff;
}

.fileUploadTableRowItem {
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 0;
	padding-right: 0;
}

.fileUploadTableProgressBar {
}

.fileUploadTableProgressBar :global(.slds-progress-bar__value) {
	background: #f26122;
}

.fileUploadTableRowAction {
	margin: 0 auto;
}

.requirement {
	width: 50%;
	word-wrap: break-word;
	align-self: center;
}

.filename {
	width: 25%;
	display: flex;
	align-items: center;
	word-wrap: break-word;
	overflow-wrap: anywhere;
}

.status {
	width: 15%;
	min-width: 150px;
	text-align: center;
	justify-content: center;
	display: flex;
	align-items: center;
	word-wrap: break-word;
}

.action {
	width: 10%;
	text-align: center;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.status :global(.slds-icon-typing) {
	margin-left: 5px;
}

.status :global(.slds-icon-typing__dot) {
	background-color: #f26122;
}

.status
	:global(
		.slds-icon-typing.slds-is-animated .slds-icon-typing__dot:nth-child(1)
	) {
	-webkit-animation-name: slds-icon-typing__dot-first_custom;
	animation-name: slds-icon-typing__dot-first_custom;
}

.status
	:global(
		.slds-icon-typing.slds-is-animated .slds-icon-typing__dot:nth-child(2)
	) {
	-webkit-animation-name: slds-icon-typing__dot-second_custom;
	animation-name: slds-icon-typing__dot-second_custom;
}

.status
	:global(
		.slds-icon-typing.slds-is-animated .slds-icon-typing__dot:nth-child(3)
	) {
	-webkit-animation-name: slds-icon-typing__dot-third_custom;
	animation-name: slds-icon-typing__dot-third_custom;
}

@keyframes slds-icon-typing__dot-first_custom {
	0% {
		background-color: #f3d3c8;
	}

	16% {
		background-color: #f2ae90;
	}

	100%,
	33% {
		background-color: #f26122;
	}
}

@-webkit-keyframes slds-icon-typing__dot-first_custom {
	0% {
		background-color: #f3d3c8;
	}

	16% {
		background-color: #f2ae90;
	}

	100%,
	33% {
		background-color: #f26122;
	}
}

@-webkit-keyframes slds-icon-typing__dot-second_custom {
	0%,
	33% {
		background-color: #f3d3c8;
	}

	50% {
		background-color: #f2ae90;
	}

	100%,
	67% {
		background-color: #f26122;
	}
}

@keyframes slds-icon-typing__dot-second_custom {
	0%,
	33% {
		background-color: #f3d3c8;
	}

	50% {
		background-color: #f2ae90;
	}

	100%,
	67% {
		background-color: #f26122;
	}
}

@-webkit-keyframes slds-icon-typing__dot-third_custom {
	0%,
	67% {
		background-color: #f3d3c8;
	}

	83% {
		background-color: #f2ae90;
	}

	100% {
		background-color: #f26122;
	}
}

@keyframes slds-icon-typing__dot-third_custom {
	0%,
	67% {
		background-color: #f3d3c8;
	}

	83% {
		background-color: #f2ae90;
	}

	100% {
		background-color: #f26122;
	}
}

.btnIcon {
	color: #f26122 !important;
	font-size: 10px !important;
	margin-left: 5px;
}

.btnIcon :global(svg path) {
	fill: #f26122 !important;
}

.error {
	color: #f26122;
}

.completeIcon,
.failedIcon {
	margin-left: 5px;
}

.errorHeader {
	font-weight: bold;
	font-size: 0.9vmax;
	margin-bottom: 10px;
}

.errorBody {
	margin-top: 20px;
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successBody {
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
