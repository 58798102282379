.datePicker {
	width: 100%;
}

.error {
	color: #d93025;
	margin-top: 0.5em;
}

.channelRow {
	cursor: pointer;
}

.channelLabel,
.selectLabel {
	font-size: var(--input-label-font-size);
}