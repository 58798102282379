.downloadButton {
	background: #5b5b5b 0% 0% no-repeat padding-box;
	border: 0;
	color: #ffff;
	font-size: 0.9rem;
}

.downloadButton:hover,
.downloadButton:active,
.downloadButton:focus {
	background: #5b5b5b 0% 0% no-repeat padding-box;
	border: 0;
	color: #ffff;
	box-shadow: #5b5b5b;
}

.downloadButton.fullWidth {
	width: 100%;
}
