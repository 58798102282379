.section {
	font-size: 12px;
	min-height: 42px;
}

.section:disabled {
	color: #c4c4c4;
}

.pill,
.pill:disabled {
	display: flex;
	justify-content: space-around;
	margin: 3px 0px 5px 4px;
	width: 155px;
	border: 1px solid #88b3e6;
	background-color: #eff8fe;
}

.pill :global(.slds-pill__remove svg) {
	fill: #88b3e6;
}

.pill:disabled :global(.slds-pill__label) {
	color: #c4c4c4;
}
