.select {
	font-family: var(--common-font);
	width: 100%;
}

.select > button {
	height: 100%;
	min-height: var(--input-min-height);
	color: #706e6b !important;
	justify-content: space-between;
	/* padding: 0.3vmax !important; */
	padding-left: 1vw !important;
	width: 100%;
	line-height: 3vh;
	font-size: var(--input-label-font-size);
}

.select.loading > button svg {
	animation: spin infinite linear 1000ms;
}

.select > button svg {
	width: 0.7vmax;
	height: 0.7vmax;
}

.select.error > button {
	border: 2px solid #ea001e;
}

.select.empty > button {
	color: #706e6b !important;
}

.select.empty > button:disabled {
	cursor: not-allowed;
}

.select > div {
	min-width: 100% !important;
}

.select > div > ul > li > a {
	font-family: var(--common-font);
	font-size: var(--input-label-font-size);
	letter-spacing: 0.11px;
}

.select > div > ul > li > a:hover {
	color: var(--orange);
	background-color: #fff;
}

.selectItem {
	font-size: var(--input-label-font-size);
	font-family: var(--common-font);
}

.selectItem:hover > a,
.selectItemActive > a {
	color: var(--orange);
	z-index: 10000000;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.helper {
	font-size: var(--input-label-font-size);
}

.dropdownContainer {
	color: #3b3b3b;
	border: #f26122;
}

.dropdownContainer > :checked {
	color: #f26122;
}
.container > div {
	padding-top: 2px;
	margin-bottom: 2px;
}

.selectItem > a > span {
	height: 1.1rem;
	display: flex;
	justify-content: center;
}

.selectItem > a > span > span {
	opacity: 1;
	border-style: solid;
	margin-right: 10px;
	border-radius: 2px;
	border-width: 1.2px;
	border-color: #dadce0;
	height: 17px;
}

.selectItem > a > span > span > svg {
	margin: 0px;
	background-color: #f26122;
	border-radius: 0px;
	/* width: 14px;
	height: 13px; */
	margin-bottom: 6px;
}

.selectItem > a > span > span > svg > use {
	filter: brightness(0) invert(1);
}
