.divBackground {
	background: transparent linear-gradient(114deg, #f26122 35%, #0176c0 100%)
		0% 0% no-repeat padding-box !important;
	height: 83vh;
	width: 90%;
	position: absolute;
	right: 0;
	border-radius: 0 0 0 500px;
	opacity: 1;
}
