.warningModal {
	align-self: center;
	border-radius: 0.25rem;
	width: fit-content;
	width: 20vw;
}

.warningModalContainer {
	align-self: center;
	top: -10vh;
	padding: 1vw;
}

.warningModalContent {
	padding: 1vw;
}

.warningIcon {
	fill: green;
	width: 4vw;
}

.warningModalIconContainer {
	display: flex;
	justify-content: center;
}

.warningModalBody > div {
	font-family: var(--common-font);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	margin: 1em 0;
}

.warningModalActions {
	display: flex;
	justify-content: center;
}

.warningModalHeader button {
	display: none;
}

.warningModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
