.modalContainer {
	width: 400px !important;
}

.customHeading {
	font-size: 16px;
	font-weight: bold;
}

.confirmationModalBtn {
	padding: 19px 6px !important;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.bodyHeader {
	padding: 5px 15px 5px 15px;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-family: 'Poppins-Regular', sans-serif;
	margin-top: 1vh;
	/* display: flex; */
}

.radioOptions {
	display: flex;
	align-items: center;
	margin-left: 2vh;
	margin-top: -3vh;
	max-width: 37vh;
}

.radioOptionsLabel {
	font-size: 0.74rem !important;
	align-items: center;
}

.radioOptions :global(.slds-radio_faux) {
	width: 15px !important;
	height: 15px !important;
	margin-top: 1vh !important;
	display: inline-block !important;
}

.radioOptions :global(.slds-radio__label) {
	display: flex;
}

.bodyContent {
	text-align: start;
	font-family: var(--common-font);
	padding: 0 15px 15px 15px;
	height: 170px;
}

.remarks {
	resize: none;
	height: 100px;
}

.bodyContent :global(.slds-form-element__label) {
	font-size: 13px !important;
	font-family: 'Poppins-Regular', sans-serif;
	font-weight: 400 !important;
}

.remarksLabel {
	font-size: 11px !important;
}

.remarksSubLabel {
	font-size: 0.7vw;
	font-family: 'Poppins-Regular', sans-serif;
	color: #8b9095 !important;
}

.btnStyleClass {
	float: right !important;
	margin: 1.9vh;
	margin-top: -1vh !important;
}
.doneButton {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
