.container {
	padding: 1vmax;
}

.title {
	font-family: var(--common-font-bold);
	font-size: 14px;
	margin-bottom: 2vh;
}

.row {
	margin-bottom: 2vh;
}
