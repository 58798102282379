.uploadContainer {
	padding-top: 22px;
}

.uploadContainer img {
	pointer-events: none;
}

.innerContainer {
	border: 2.5px dashed var(--unnamed-color-dadce0);
	padding: 16px 0px 18px 0px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
}

.border {
	border: 2.5px dashed #dadce0;
}

.borderError {
	border: 2.5px dashed #ea001e;
}

.spiel {
	font-style: italic;
	color: #8b9095;
	padding: 11px 0px 15px 0px;
}

.secondLabel {
	font-style: italic;
	color: #8b9095;
	font-size: 12px;
	padding-bottom: 10px !important;
}

span.outline {
	color: #f26122;
	background: #ffffff;
	border: 1px solid #f26122;
	border-radius: 4px;
	opacity: 1;
	font-size: 14px;
	padding: 8px 25px;
}

span.outline:hover,
span.outline:active,
span.outline:focus {
	color: #ffffff;
	background: #f26122;
	border: 1px solid #f26122;
}

span.outline:disabled {
	color: #f6a07b;
	background: #ffffff;
	border: 1px solid #f6a07b;
}

.logoSubtext {
	font-size: 10px;
	color: #8b9095;
}

.logoSubtextValue {
	color: black;
	font-weight: bold;
	margin-left: 0.4vw;
}

.fileRow {
	display: flex;
	flex-direction: row;
	padding: 0px 10px 15px 5px;
}

.fileRowContainer {
	flex: 1;
	display: flex;
	overflow: auto;
	margin-top: 16px;
}

.labelRequired::after {
	color: #d93025;
	content: '*';
	margin-left: 0.1vw;
}

.actionsContainer {
	width: 100%;
	display: flex;
	column-gap: 12px;
	justify-content: center;
}

.actionsContainer button {
	width: 8vw;
	padding: 4vh auto;
}

.leftAlign {
	text-align: left;
	padding-left: 10px;
}
