.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successBody {
	font-size: 0.8vmax;
	margin-bottom: 20px;
}

.successModalBtn {
	margin-bottom: 3vh;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
