.errorHeader {
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 12px;
}

.errorBody {
	font-size: 12px;
	width: 352px;
}

.errorFooter {
	padding-bottom: 32px;
}

.errorModalBtn {
	margin-bottom: 7.5px;
	padding: 20px;
	width: 140px;
	height: 42px;
	font-size: 14px !important;
}
