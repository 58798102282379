.loadMoreBtn {
	margin-top: 1vh;
	border: none !important;
	width: 100%;
	color: var(--orange) !important;
}

.loadMoreBtn:focus,
.loadMoreBtn:active {
	background-color: #ececec !important;
}

.loadMoreBtn:hover {
	background-color: #fdfdfd !important;
}

.noChannel {
	color: gray;
	text-align: center;
}

.searchBar {
	width: 100%;
}

.searchBar::placeholder {
	color: #8b9095;
}

.listContainer {
	border: 1px solid #dadce0;
	border-radius: 10px;
	padding: 20px;
}

.listTitle {
	padding: 10px;
	font: normal normal 14px/13px var(--common-font-bold);
	letter-spacing: 0px;
	color: #36393d;
}

.scrollableContainer {
	overflow-y: auto;
	max-height: 400px;
	margin-top: 22px;
	position: relative;
}

.dropdownContainer {
	position: relative;
}

.dropdownBorderless button {
	border: none !important;
}

.dropdownNoMargin button {
	padding: 0 !important;
	padding-left: 5px !important;
}

.listNav {
	color: #0176c0;
}

.listNavItem {
	font-weight: bold;
}

.listNavItemPointer {
	cursor: pointer;
}

.listNavItemPointer:hover {
	cursor: pointer;
	text-decoration: underline;
}

.filterButton {
	font-size: 12px;
	color: #706e6b;
	display: flex;
	align-items: center;
}

.filterIcon {
	margin-left: 50px;
}

.panel {
	position: absolute;
	z-index: 999;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	width: 250%;
}

.panelBody {
	display: grid;
	grid-template-columns: 40% 20% 40%;
	grid-gap: 10px;
	justify-items: center;
}

.topPanel {
	display: flex;
}

.topPanel label {
	display: flex;
	align-items: center;
}

.selectAll {
	flex-grow: 1;
}

.selectAll input[type='checkbox'] {
	display: none;
}

.clearAll {
	text-align: right;
}

.clearButton {
	padding: 8px;
	background: none;
	border: none;
	cursor: pointer;
	font-size: 12px;
	color: #706e6b;
}

.clearIcon {
	margin-right: 5px;
}

.posCol div {
	margin-top: 15px;
}

.storeCol input[type='checkbox'] {
	display: none;
}

.storeCol div {
	margin-top: 15px;
	margin-bottom: 15px;
}

.machineLocCol input[type='checkbox'] {
	display: none;
}

.machineLocCol div {
	margin-top: 15px;
}

.confirmationHeader {
	font-size: 18px;
}

.subtext {
	margin-top: 2vh;
	font-size: 0.7vw;
}

.successBtn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	height: 40px;
	border-radius: 4px;
}
