.successHeader {
	font-weight: normal;
	font-size: 1.2vmax;
	margin-bottom: 20px;
	margin-top: 5px;
}

.successBody {
	font-size: 0.8vmax;
}
.successModalContainer {
	margin-top: 85px;
	max-height: 293px;
	min-height: 293px;
	max-width: 372px;
	min-width: 372px;
}

.successModalBtn {
	margin-top: 30px;
	padding: 19px 6px;
	width: 140px;
	height: 42px;
	font-size: 13px !important;
}
