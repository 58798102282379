.datepicker {
	width: 100%;
	font-size: var(--input-label-font-size);
	font-family: var(--common-font);
}

.datepicker > div > div > input {
	min-height: var(--input-min-height);
	height: 100%;
	letter-spacing: 0px;
}

.datepicker > div > label {
	letter-spacing: 0px;
	color: #3b3b3b;
	font-size: 0.9vw;
}

.datepicker :global(button.slds-button_icon) {
	width: 0.7vmax;
}

.datepicker :disabled {
	opacity: 0.75;
}
