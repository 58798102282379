.tabs {
	overflow-y: hidden;
}
.tabs :global(.slds-tabs_default__link) {
	text-decoration: none !important;
}

.tabs :global(.slds-tabs_default__item.slds-is-active:after),
.tabs :global(.slds-tabs_default__item:hover:after) {
	background-color: var(--orange);
}

.tabs :global(.slds-disabled) {
	pointer-events: none;
}

.tabs :global(.slds-tabs_default__item) {
	height: 4.5vh;
	padding-left: 0.5vw;
	padding-right: 0.5vw;
	font-size: 0.8vw;
}

.tabLabel {
	width: 170px;
	text-align: center;
	font-size: 15px;
}

.tabDisabled {
	color: gray;
	pointer-events: none;
	cursor: not-allowed !important;
}

.tabCheck {
	margin-left: 0.5vw;
	color: #28a745;
}

.content {
	overflow-y: auto;
	height: 70vh;
}
