/* Horizontal */
.containerHorizontal fieldset {
	display: flex;
	flex-direction: row;
}

.centerHorizontally {
	width: 100%;
	justify-content: center;
}

.centerHorizontally :global(.slds-form-element__control) {
	width: 100%;
}

.centerHorizontally :global(.slds-radio) {
	flex: 1 1;
}

.containerHorizontal :global(.slds-form-element__label) {
	font-size: var(--input-label-font-size) !important;
}

.containerHorizontal legend:global(.slds-form-element__label) {
	font-family: var(--common-font);
	font-size: 11px !important;
	font-weight: 500;
	padding-top: 0;
	line-height: 20px;
	color: var(--sds-c-modal-content-text-color, var(--sds-c-modal-text-color));
	/* display: none; */
}

.containerHorizontal span:global(.slds-form-element__label) {
	font-size: 11px !important;
	margin-right: 10px;
}

.containerHorizontal .radioOptionsBelow {
	flex-direction: row;
}

/* Custom label - Horizontal */
.horizontalLabel{
	display: flex;
	/* margin-top: 0.44em; */
	margin-right: 55px;
	font-size: 11px;
}

/* Vertical */
.containerVertical fieldset {
	display: flex;
	flex-direction: column;
}

.containerVertical :global(.slds-form-element__label) {
	font-size: var(--input-label-font-size) !important;
}

.containerVertical legend:global(.slds-form-element__label) {
	font-family: var(--common-font);
	font-size: 11px !important;
	font-weight: 500;
	padding-top: 0;
	color: var(--sds-c-modal-content-text-color, var(--sds-c-modal-text-color));
}

.containerVertical span:global(.slds-form-element__label) {
	font-size: var(--input-label-font-size) !important;
}

.containerVertical .radioOptionsBelow {
	flex-direction: column;
}

/* Generic */
.radioOptions {
	display: flex;
}

.radioOptionsBelow {
	display: flex;
	margin-left: 0;
}

.radio {
	color: blue;
}

.radio.checked :global(.slds-radio_faux) {
	border: 0.15vmax solid;
}

.radio :global(.slds-radio_faux:after) {
	width: 0.4vw !important;
	height: 0.8vh !important;
}

.radio.checked :global(.slds-radio_faux:after) {
	background-color: var(--orange) !important;
	border-color: var(--orange) !important;
}

.radio :global(.slds-radio_faux) {
	width: 0.95vmax !important;
	height: 0.95vmax !important;
}

.radio.checked :global(.slds-radio_faux) {
	border-color: var(--orange) !important;
}

.radio.horizontalRadio :global(.slds-radio_faux:after) {
	display: flex !important;
	padding-top: 0;
}

.radio.horizontalRadio :global(.slds-form-element__label) {
	padding-top: 0;
}

.radio.horizontalRadio :global(.slds-radio__label) {
	display: flex
}

.helper {
	font-size: var(--input-label-font-size);
}

.selectError {
	outline: 2px solid #ea001e;
	margin-right: 13px;
}

.labelRequired :required {
	margin-left: 0 !important;
}

.indented {
	padding-left: 15px;
}

.subtext {
	color: #8b9095;
	font-size: 0.6vw;
	margin-bottom: 5px;
}
