.container {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 9000;
}

.progressContainer {
	left: 50%;
	top: 50%;
	position: fixed;
	overflow-y: hidden;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.progress {
	width: 4vmax;
}

.progressInfinite {
	overflow: hidden;
}

.progress text {
	transform: translate(-1.3rem, 0.4rem);
}

.message {
	font-size: 0.8vw;
	font-family: var(--common-font);
	margin-bottom: 2vh;
	color: white;
}

@keyframes dash {
	0% {
		transform: rotate(-41deg);
	}
	100% {
		transform: rotate(319deg);
	}
}

.highlightRoundBox {
	position: relative;
	width: 62px;
	height: 62px;
}

.loading {
	display: block;
	position: relative;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	border: 7px solid rgba(15, 62, 95, 0.7);
	border-right-color: #f26122;
	animation: dash 1s ease-in-out infinite;
}

.loading .edge {
	display: block;
	position: absolute;
	border-radius: 50%;
	width: 7px;
	height: 7px;
	background: #f26122;
}

.loading .edge.edgeLeft {
	top: 0rem;
	left: 2.45rem;
}
.loading .edge.edgeRight {
	top: 2.45rem;
	right: 0rem;
}

.bayadLogo {
	top: 0.85rem;
	right: 0.51rem;
	position: absolute;
	width: 44px;
	height: 34px;
}
