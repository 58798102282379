.selectWithOthersContainer {
	padding: 0;
}

.selectWithOthersContainer > *:first-child {
	padding-left: 0;
}

.selectWithOthersContainer > *:last-child {
	padding-right: 0;
}

.ootherMain > *:first-child {
	padding-left: 0;
}

.otherMain > *:last-child {
	padding-right: 0;
}

.othersTextField {
	display: flex;
	align-items: flex-end;
	padding-right: 0 !important;
}

.otherLabel {
	color: transparent;
	opacity: 0;
}

.otherLabel * {
	pointer-events: none;
}

.othersSelectField {
	padding-left: 0 !important;
}
