.container {
	display: flex;
	flex-direction: column;
	gap: 2;
}

.container > * {
	padding: 1em 0;
}

.headerContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.headerRow {
	padding: 0.3em 0;
	max-width: 300px;
}

.bold {
	font-weight: bold;
}

.table {
	display: flex;
	overflow-x: auto;
	white-space: -moz-nowrap; /* Firefox */
	white-space: -o-nowrap; /* Opera */
	white-space: nowrap; /* Chrome */
	word-wrap: unset; /* IE */
}

.table > * {
	flex: auto;
}

.th {
	color: #ffffff;
	background-color: #0176c0;
}

.left.th {
	border-top-left-radius: 10px;
}

.right.th {
	border-top-right-radius: 10px;
}

.right.td {
	border-right: 1px solid #dadfe2;
	text-align: right;
}

.left.td {
	border-left: 1px solid #dadfe2;
}

.column {
	font-size: 0.8rem;
	padding: 1em 0.6em;
	border-bottom: 1px solid #dadfe2;
}

.td {
	min-height: 4em;
}

.td:nth-child(odd),
.odd {
	background-color: #f3faff;
}
