.filters > div,
.innerButtons > div {
	padding-left: 0.2em;
	padding-right: 0.2em;
}

.innerButtons {
	padding-top: 2.2em;
}

.filterContainer button {
	white-space: nowrap;
	overflow: hidden;
}

.selectLabel {
	font-size: var(--input-label-font-size);
}

.searchBar {
	width: 100%;
}

.searchBar::placeholder {
	color: #8b9095;
	padding-left: 10px;
}
