.select :global(.slds-button) {
	font-size: 0.7vw !important;
	line-height: 0.7vw !important;
	border-radius: 16px;
	color: white !important;
	padding: 0.5vh 1vw !important;
	height: 15px;
	background-color: #8b9095;
}

.select :global(.slds-button > *) {
	color: white !important;
}

.select a {
	color: white;
}

.active :global(.slds-button) {
	background-color: #14bb02;
}

.active a,
.active a:hover {
	color: #14bb02;
}

.approved :global(.slds-button),
.draft :global(.slds-button),
.underDevelopment :global(.slds-button),
.forActivation :global(.slds-button),
.forReview :global(.slds-button) {
	background-color: #cccf19;
}

.select .approved a,
.select .draft a,
.select .underDevelopment a,
.select .forActivation a,
.select .forReview a {
	color: #cccf19;
}

div.forReview {
	color: var(--orange);
}

div.approved {
	color: #14bb02;
}

div.draft {
	color: #f26122;
}

div.rejected {
	color: red;
}

.rejected :global(.slds-button),
.suspended :global(.slds-button),
.deactivated :global(.slds-button) {
	background-color: red;
}

.select .rejected a,
.select .suspended a,
.select .deactivated a {
	color: red;
}

.terminated :global(.slds-button) {
	background-color: #8b9095;
}

.select .terminated a {
	color: #8b9095;
}

.bodyContent {
	padding: 0 15px 15px 15px;
}
.bodyContent :global(.slds-form-element__label) {
	font-size: 11px !important;
	font-family: 'Poppins-Regular', sans-serif;
	font-weight: 400 !important;
}

.remarks {
	resize: none;
	height: 100px;
}
.modalContainer {
	width: 400px !important;
}

.errorModalBtn {
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}

.successMessage {
	display: flex;
	height: 8.2vh;
	padding: 1vh 0.5vw 0 0.5vw;
}

.successModalBtn {
	margin-bottom: 20px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.saveModalBtn,
.approvalModalBtn,
.rejectionModalBtn {
	padding: 19px 6px !important;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
.errorHeader {
	font-weight: bold;
	font-size: 20px !important;
	margin-bottom: 10px;
}
.errorMessage {
	font-size: 10pt !important;
}
