.statusReview,
.iconOrange {
	color: var(--orange);
}

.iconBtn {
	width: 1vw;
	height: 1vw;
}

.centerAlign {
	text-align: center;
	width: 100%;
}

.toolTip {
	width: 100%;
}

.toolTip > div {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.toolTip > div > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.rowBreak {
	word-break: break-all;
}

.redStatus {
	color: red;
}
