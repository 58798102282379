.container {
	padding-top: 1vmax;
}

.container > * {
	margin-bottom: 2vh;
}

.customFieldContainer {
	line-height: 3vh !important;
}

.avatar {
	border-radius: 50%;
	background-color: #99d7ff;
	width: 80px;
	height: 80px;
	max-width: 6vw;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2.0vw;
	color: white;
	position: relative;
}

.uploadBtn {
	background-color: #0176c0;
	display: flex;
	justify-content: center;
	min-height: 0 !important;
	width: 3vh;
	height: 3vh;
	font-size: 1.0vw !important;
	border-radius: 50%;
	position: absolute;
	left: 47px;
	top: 55px;
	max-height: none;
}

.uploadBtn svg {
	color: white;
	fill: white;
	stroke: white;
}

.avatar .image {
	width: 70%;
}

.hasImage {
	background-color: transparent;
	border: 1px solid #c8c8c8;
}

.logoFieldContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 80px;
	vertical-align: top;
}

.logoLabelContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-left: 10px;
	margin-bottom: 10px;
}

.logoLabel {
	font-size: 0.7vw;
	margin-bottom: 1vh;
}

.logoSubtext {
	color: #8b9095;
	font-size: 0.6vw;
}

.logoSubtextValue {
	color: black;
	font-weight: bold;
	font-size: 0.6vw !important;
	margin-left: 0.3vw;
}

.selectWithOthersContainer {
	padding: 2;
}

.selectWithOthersContainer > *:first-child {
	padding-left: 0;
}
.selectWithOthersContainer > *:last-child {
	padding-right: 0;
}

.otherIndustry > *:first-child {
	padding-left: 0;
}

.otherIndustry > *:last-child {
	padding-right: 0;
}

.othersTextField {
	display: flex;
	align-items: flex-end;
}

.otherLabel {
	color: transparent;
	opacity: 0;
}

.otherLabel * {
	pointer-events: none;
}

.addButton {
	display: inline-block;
	transform: translateY(100%);
}

.addButton svg {
	margin-right: 0.5vw;
	fill: var(--blue);
	width: 1vmax;
	height: 1vmax;
}

.affiliateContainer {
	margin-bottom: 15px;
	position: relative;
	display: flex;
	align-items: center;
}

.deleteAffiliateButton {
	position: absolute;
	top: 0;
	right: 10px;
}

.firstDeleteAffiliateButton {
	top: 23px;
}

.deleteAffiliateButton:hover path,
.deleteAffiliateButton:focus  path,
.deleteAffiliateButton:active  path {
	color: #e1571c;
	fill: #e1571c;
}

.partnerCodeModalContainer div[class*='Table_paginationContainer'] {
	color: #707070;
}

.partnerCodeModalContainer div[class*='channelRow'] {
	font-size: 12px !important;
	padding-left: 14px;
	padding-top: 14px;
	padding-bottom: 14px;
}

.partnerCodeModalContainer div[class*='channelRow']:hover {
	color: #f26122;
}

.noMarginBottom {
	margin-bottom: 7px;
}

.checkIcon {
	position: absolute;
	top: 0;
	right: 0;
}
