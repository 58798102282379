.sessionButton,
.sessionButton:hover {
    min-width: 140px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #F26122;
}

.sessionButton.cancel {
    color: #F26122;
    background: #FFFFFF;
}
.sessionButton.cancel:active,
.sessionButton.cancel:hover {
    color: #FFFFFF;
    background: #F26122;
}

.sessionButton.add {
    color: #FFFFFF;
    background: #F26122;
}

.sessionButton:active,
.sessionButton:hover {
    color: #FFFFFF;
    background: #E1571C;
}

.sessionButton{
    color: #E1571C;
}

.sessionText{
    font-family: 'Poppins', sans-serif;
    font-size: large;
    text-align: center;
}

.sessionHeader{
    font-family: 'Poppins', sans-serif;
    font-size: x-large;
    text-align: center;
}

