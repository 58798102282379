.addButton svg {
	margin-right: 0.5vw;
	fill: var(--blue);
	width: 1vmax;
	height: 1vmax;
}

.addButton:disabled svg {
	fill: #c3c3c3;
}

.confirmTextName {
	font-weight: bold;
}

.confirmTextHeader {
	font-size: 18px;
	font-family: 'wavehaus-95-semibold', sans-serif;
}

.addressErrorCloseBtn {
	min-width: 20%;
}

.successModalText {
	font-size: 18px;
	margin-top: 10px;
	font-family: 'Wavehaus-Semibold', sans-serif;
}

.successModalBtnDone {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
