.path {
	position: relative;
}

.row {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	margin-bottom: 20px;
}

.module,
.partner {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	text-align: left;
	width: 150px;
}

.module {
	background: #f2f3c5;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	left: 0;
	clip-path: polygon(0% 0%, 75% 0%, 82% 50%, 75% 100%, 0% 100%);
	padding: 6px 10px;
	font-size: 12px;
}

.partner {
	background: #cccf19;
	left: 108px;
	clip-path: polygon(75% 0%, 82% 50%, 75% 100%, 0% 100%, 7% 50%, 0% 0%);
	padding: 6px 17px;
	font-size: 12px;
	font-weight: bold;
}
.module:hover {
	background: #e9eb9a;
	cursor: pointer;
}

.module:active {
	background: #cccf19;
}
