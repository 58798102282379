.loadMoreBtn {
	width: 100%;
	text-align: center;
	color: #f26122;
	font-weight: 700;
	cursor: pointer;
	font-size: 14px;
}

.container {
	padding: 0 12px;
}

.tabMaxheightAndWidth {
	height: 21rem;
}

.placeholderImg {
	margin: 0 auto;
	display: block;
}

.placeholderTitle {
	font: normal normal 14px/21px var(--common-font-bold);
	color: #f26122;
	text-align: center;
	margin-top: 11px;
}

.errorHeader {
	font-size: 1vmax;
	margin-bottom: 10px;
}

.errorBody {
	font-size: 0.8vmax;
	padding-left: 30px;
	padding-right: 30px;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
