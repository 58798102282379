.modalContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 439px;
	transform: translate(-50%, -50%);
}

.modalReasonContainer {
	width: 500px;
}

.modalHeading {
	display: flex;
	justify-content: center;
	font-size: 18px;
	letter-spacing: 0px;
	color: #1e1e1e;
	opacity: 1;
}

.modalReasonHeading {
	display: flex;
	text-align: left;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0px;
	color: #1e1e1e;
	opacity: 1;
	margin: 0.25vw;
}

.modalBodyContainer {
	display: flex;
	flex-direction: column;
	margin: 25px 17px 26px 20px;
}

.modalBodyHeader {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;

	& div:nth-child(2) {
		text-align: center;
		font: italic normal normal 10px/18px 'Poppins-Regular', sans-serif;
		letter-spacing: 0px;
		color: #8b9095;
		opacity: 1;
		padding-left: 58px;
		padding-right: 58px;
	}
}

.modalReasonBodyContentlabel {
	text-align: left;
	font-size: 13px !important;
	letter-spacing: 0px;
	color: #3b3b3b;
	opacity: 1;
}

.modalRemarkslabel {
	text-align: left;
	font-size: 12px !important;
	letter-spacing: 0px;
	color: #3b3b3b;
	opacity: 1;
}

.modalRemarksSubLabel {
	text-align: left;
	font-size: 11px;
	letter-spacing: 0px;
	color: #8b9095;
	opacity: 1;
}

.modalReasonRemarksSubLabel {
	text-align: left;
	font-size: 11px;
	letter-spacing: 0px;
	color: #8b9095;
	opacity: 1;
}

.modalRemarks {
	height: 100px;
}

/* .modalRemarks:focus {
	height: 100px;
	border: 1px solid red;
	outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
} */

.errorRemarks {
	height: 100px;
	border: 1px solid red;
}

.errorRemarks:focus,
.errorRemarks:active {
	height: 100px;
	box-shadow: none;
	border: 1.5px solid red;
}

.modalBtn {
	padding: 19px 6px !important;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.textHeaderContainer {
	position: relative;
	display: flex;
	align-items: center;
}

.statusHeader {
	margin-left: 15px;
}
