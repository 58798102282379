.checkboxContainer {
	border: 1px solid #f26122;
	border-radius: 1em;
	min-width: 200px;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.5em;
	cursor: pointer;
}

/* Media query to handle screen size less than 1400px */
@media (max-width: 1390px) {
	.checkboxContainer {
	  min-width: 180px;
	}
}

.checkboxGroupContainer {
	display: flex;
	justify-content: center;
	gap: 1em;
}

.checkbox {
	align-self: start;
}

.checkboxBody {
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	align-items: center;
}

.checkboxBody :global(.slds-form-element__label) {
	font-size: 0.7rem !important;
}

.checkboxFooterContainer {
	display: flex;
	flex-direction: row;
	gap: 0.2em;
}

.checkboxFooterContainer {
	display: block;
}

.disabled {
	cursor: default;
	pointer-events: none;
}
