.errorHeader {
	font-size: 1vmax;
	margin-bottom: 10px;
}

.errorBody {
	font-size: 0.8vmax;
	padding-left: 30px;
	padding-right: 30px;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
