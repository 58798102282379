.searchBar {
	width: 100%;
}

.searchBar::placeholder {
	color: #8b9095;
}

.listContainer {
	border: 1px solid #dadce0;
	border-radius: 10px;
	padding: 20px;
}

.listTitle {
	padding: 10px;
	font: normal normal 14px/13px var(--common-font-bold);
	letter-spacing: 0px;
	color: #36393d;
}

.dropdownButton {
	width: 100%;
	font-family: var(--common-font-light);
	font-size: var(--input-label-font-size);
}

.dropdownInitState {
	color: #8b9095;
}

.dropdownActiveState {
	color: #3b3b3b;
}

.dropdownTriggerButton {
	font-family: var(--common-font);
	display: block;
	margin-top: 14px;
}

.dropdownTriggerButton > button > svg {
	fill: #8b9095;
}

.dropdownItem > ul > li > a:hover {
	color: var(--orange);
}

.scrollableContainer {
	overflow-y: auto;
	max-height: 400px;
	margin-top: 22px;
}

.clearIcon {
	position: absolute;
	left: 91%;
	top: 15%;
	cursor: pointer;
}

.clearIcon svg:hover {
	fill: var(--sds-c-button-text-color-hover, #014486) !important;
}

.dropdownContainer {
	position: relative;
}

.dropdownBorderless button {
	border: none !important;
}

.partnerSearch input {
	height: 46px !important;
}

.partnerSearch {
	font: normal normal normal 12.5px/18px 'Poppins-Regular', sans-serif;
}

.partnerSearch input::placeholder {
	color: #8b9095;
	position:relative;
	left:1rem;
}

.partnerTitle {
	font-size: 1vw;
    display: flex;
	flex-direction: row;
    align-items: center;
}

.dropdownNoMargin button {
	padding: 0 !important;
	padding-left: 5px !important;
}
