.overlayButton {
	border-radius: 50px;
	min-width: 25px;
	padding: 10px;
	background: #f1f4f8;
	color: #333;
	z-index: 12;
}

.overlayControls {
	flex-direction: row;
	justify-content: right;
	gap: 8px;
	display:none;
}

.overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	border-radius: 4px;
	padding: 10px;
}

.card {
	z-index: 10;
	position: relative;
	cursor: pointer;
	box-shadow: 0px 3px 5px #aaaaaa;
	border-radius: 4px;
	margin: 10px 0;
	font-size: 12px;
	width: 160px;
	margin-right: 1px;
}

.card:hover .overlay {
	background: rgba(50, 50, 50, 0.6);
	color: #ffffff;
	z-index: 11;
}

.card:hover .overlayControls {
	display: flex;
}

.attachmentImage {
	height: 84px;
	justify-content: center;
	display: flex;
	padding: 0.5vh;
	border-radius: 4px;
	background: #dadce0;
}

.attachmentNoPreview {
	height: 84px;
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 0.5vh;
	border-radius: 4px;
	background: #dadce0;
}

.attachmentName {
	overflow: hidden;
	white-space: nowrap;
	text-align: center;
	text-overflow: ellipsis;
	font-family: 'Poppins-Regular', sans-serif;
	font-size: 10px;
	padding: 5% 5% 5% 5%;
}
