.modal {
	align-self: center;
	border-radius: 0.25rem;
	width: fit-content;
	width: 20vw;
}

.larger {
	width: 29vw;
}

.modalContainer {
	align-self: center;
	top: 0vh;
}

.container {
	padding: 0vw;
}

.modalBody > div {
	font-family: var(--common-font);
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 1em 0;
}

.modalActions > div {
	display: flex;
	justify-content: center;
	padding: 20px;
}

.header button {
	display: none;
}
.uploadSuccessErrorSubText {
	font-family: var(--common-font-bold);
	margin: 0;
}
