.dropdown {
	font-family: var(--common-font);
	width: 1.5vw;
}

.logout > ul > li > a:hover {
	color: var(--orange);
}

.logoutIcon > ul > li > a:hover > span > span > svg {
	fill: var(--orange);
}

.button {
	border-radius: 50%;
	width: 1.5vw;
	height: 1.5vw;
}
