.fileCard {
	height: 118px;
	width: 172px;
	margin-right: 10px;
	background: var(--unnamed-color-dadce0) 0% 0% no-repeat padding-box;
	background: #dadce0 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 4px #0000003b;
	border-radius: 4px;
	border: 1px gray;
	opacity: 1;
	position: relative;
}

.previewContainer {
	height: 84px;
	justify-content: center;
	display: flex;
	padding: 0.5vh;
}

.imagePreview {
	width: 45%;
}

.labelContainer {
	background-color: white;
	height: 34px;
	display: flex;
	flex: 1;
	justify-content: center;
	text-align: center;
	word-wrap: break-word;
	flex-direction: column;
}

.mainDiv,
.hoverDiv {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	border-radius: 4px;
}
/* .hidden {
	display: none;
} */

.hoverDiv {
	height: 118px;
	width: 172px;
	background-color: black;
	opacity: 0.63;
	z-index: 2;
	display: none;
}

.shown {
	display: flex !important;
}

.buttonContainer {
	opacity: 1 !important;
	margin-right: 0px;
	margin-left: auto;
	padding: 5px;
}

.buttonContainerDelete {
	opacity: 1 !important;
	margin-left: 139px;
	padding: 5px;
	position: absolute;
}

.buttonDelete .previewButton {
	cursor: pointer;
}

.refreshButton {
	margin: auto;
}

.pdfThumbnailContainer > div > div > div {
	overflow: hidden !important;
}

.pdfThumbnailContainer > div > div > div > div {
	padding: 0 !important;
}

.fileNameContainer {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.fileNameContainerHovered {
	overflow: visible;
}
