.errorHeader {
	font-weight: bold;
	font-size: 0.9vmax;
	margin-bottom: 10px;
}

.errorBody {
	margin-top: 20px;
	font-size: 0.8vmax;
}

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}