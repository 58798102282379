.addButton svg {
	margin-right: 0.5vw;
	fill: var(--blue);
	width: 1vmax;
	height: 1vmax;
}
.addButton:disabled svg {
	fill: #c3c3c3;
}
.contactDetailsErrorCloseBtn {
	min-width: 40%;
}
.confirmTextName {
	font-size: 12px;
	font-weight: bold;
}
.confirmTextHeader {
	font-size: 24px;
	font-weight: bold;
}
.confirmTextTop {
	display: block;
	font-size: 12px !important;
}
.confirmTextBottom {
	display: block;
	font-size: 12px !important;
	font-weight: bold;
}
.questionMark {
	font-weight: normal;
	font-size: 12px !important;
}
.confirmPartnerContactDetails {
	font-weight: bold;
	font-size: 13px;
}
.firstEntry {
	padding-top: 0;
}
.successModalText {
	font-size: 16px;
	margin-top: 1vh;
	margin-bottom: 2vh;
	padding-left: 3vh;
	padding-right: 3vh;
}
.successHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
}
.errorHeader {
	font-weight: bold;
	font-size: 13px !important;
	margin-bottom: 10px;
	/* width: 30vh; */
}
.errorMessage {
	font-size: 9pt !important;
}
.successModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
.errorModal {
	width: 25vw !important;
}
.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.headerText {
	font-family: var(--common-font);
	font-size: 1vw;
	font-weight: bold;
	letter-spacing: 0px;
	color: #1e1e1e;
}

.headerClass {
	font-size: 15px !important;
	font-weight: bold;
}

.collectionContainer {
	margin-left: -1vw;
	margin-right: -1vw;
}
