.headerContainer {
	border: none;
	padding-bottom: 0;
}

.modal {
	font-family: var(--common-font);
	overflow-y: scroll;
	padding: 1vw;
}

.header {
	overflow: auto;
	overflow-y: hidden;
	padding-top: 1vmax;
	padding-left: 0.5vmax;
	padding-right: 0.5vmax;
}

.titleActionBtnContainer {
	display: block;
	display: flex;
	justify-content: right;
	min-height: 1.8vw;
}

.btn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}

.successBtn {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	height: 40px;
	border-radius: 4px;
}

.btnSave {
	padding: 19px 6px !important;
	min-width: 120px !important;
	font-size: 13px !important;
	word-wrap: break-word;
	border-radius: 4px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin-right: 2vmax;
}

.confirmationHeader {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-bottom: 10px;
	margin-top: 1vh;
}

.confirmationSaveChanges {
	font-weight: bold;
	font-size: 1.2vmax;
	margin-top: 1vh;
}

.btn:hover .btnIcon :global(path),
.btn:active .btnIcon :global(path),
.btn:focus .btnIcon :global(path) {
	fill: #ffffff;
}

.titleActionTextContainer {
	display: block;
}

.titleActionText {
	color: #8b9095;
	font-size: 0.8vw;
	margin-top: 5px;
}

.titleRightPanel {
	float: right;
	display: flex;
	justify-content: right;
	flex-direction: column;
}

.titleLeftPanel {
	float: left;
	display: flex;
	justify-content: left;
	flex-direction: column;
}

.body {
	padding: 1vmax;
}

.footer {
	overflow: auto;
	overflow-y: hidden;
}

.footerRightPanel {
	float: right;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 0.5vw;
}

.container {
	padding-top: 2vh;
	padding-bottom: 10vh;
	overflow-y: scroll;
	height: 70vh;
}

.modalContainer {
	align-self: center;
	top: 0vh;
}

.confirmModalContainer {
	align-self: center;
	top: 0vh;
	max-width: 420px;
	width: 80%;
	text-align: center;
}

.confirmationBody {
	height: 100px;
}

.intergrationConfirmationBody {
	height: 230px;
}

.intergrationModalBody {
	text-align: start;
	margin: 0 1vmax;
}

.successMessage {
	display: flex;
	height: 10.2vh;
	padding-top: 2vh;
}

.successBtnAlign {
	margin-bottom: 1vh;
}

.subtext {
	font-size: 0.8vw;
}

.subtext b {
	font-size: 0.8vw;
}

.divider {
	border-top: 0.5vh solid #0176c0;
	margin: 0 1vmax;
}

.footerDivider {
	border-top-width: 2px;
	margin: 0 1vmax;
	padding-bottom: 0.75rem;
}

.spm {
	display: flex;
	font-size: var(--input-label-font-size);
}

.spmText {
	top: 1vh;
	position: relative;
}

.spmTextField {
	width: 100%;
}

.remarks {
	resize: none;
	height: 100px;
	border-radius: 4px;
	font-size: var(--input-label-font-size);
}

.remarks[disabled] {
	background-color: #fff;
	color: #bababa;
}

.customLabel {
	font-size: 11px !important;
	font-family: 'Poppins-Regular', sans-serif;
	font-weight: 400 !important;
	color: #080707;
	margin-bottom: 0.8vh;
}

.required {
	color: #d93025;
	margin-left: 0.1vw;
}

.remarksSubText {
	font-size: 0.7vw;
	font-family: 'Poppins-Regular', sans-serif;
	color: #8b9095;
}

.remarksError {
	font-size: var(--input-label-font-size);
	color: #ea001e;
}

.customDropdown {
	margin-bottom: 8px;
	font-size: var(--input-label-font-size);
}

.combobox {
	position: relative;
	font-size: var(--input-label-font-size);
}

.inputContainer {
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 4px;
}

.inputContainer input {
	flex: 1;
	border: none;
	outline: none;
	padding-left: 0.7vw !important;
}

.iconContainer {
	cursor: text;
	display: flex;
	align-items: center;
}

.icon {
	width: 0.7vmax;
	height: 0.7vmax;
	margin-left: 8px;
	color: #706e6b;
}

.panel {
	position: absolute;
	z-index: 999;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 3px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
	width: 100%;
	margin: 2px 0px;
	padding: 4px 0px;
	max-height: 210px;
	height: auto;
	overflow-y: scroll;
}

.dropdownItems {
	font-size: 12px;
	padding: 8px 12px;
}

.dropdownItems:hover {
	background-color: #f3faff;
	color: #f26122;
}

.bodyHeader {
	padding: 0 15px 0 15px;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-family: 'Poppins-Regular', sans-serif;
	margin-top: 1vh;
	height: 90px;
	display: flex;
}
