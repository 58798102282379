.cardContainer {
	padding: 16px;
	border-radius: 10px;
	font-family: var(--common-font);
	font-size: 0.7vw;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.cardLabel {
	text-align: left;
	font-size: 15px;
	letter-spacing: -0.24px;
	color: #3b3b3b;
	margin-top: 0.5px;
}

.cardContent {
	display: flex;
	align-items: center;
	margin-top: 8px;
}

.cardValue {
	text-align: left;
	font-size: 28px !important;
	font-weight: bold;
	color: #3b3b3b !important;
	padding-left: 15px;
}

.paperContainer {
	padding: 12px 11px;
	border-radius: 20px;
}

.paperContainerFooter {
	text-align: right;
	margin-top: 12px;
	color: #3b3b3b;
}

/* Large icon format */
.largeIcon {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.liCardIcon > svg {
	height: 5vh;
	width: 5vw;
}

.liCardContent {
	flex-direction: column;
	margin-left: 16px;
	color: #3b3b3b !important;
}

.liCardValue {
	text-align: left;
	font-size: 28px !important;
}

.liCardLabel {
	font-size: 15px;
	font-family: var(--common-font-bold);
}
