.Breadcrumbs {
	list-style: none;
	overflow: hidden;
	padding: 0;
}
.Breadcrumbs li {
	float: left;
}
.Breadcrumbs li a {
	color: white;
	text-decoration: none;
	padding: 8px 0 8px 40px;
	background: #fafca4;
	position: relative;
	display: block;
	float: left;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}
.Breadcrumbs li a:after {
	content: ' ';
	display: block;
	width: 0;
	height: 0;
	border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
	border-bottom: 50px solid transparent;
	border-left: 30px solid #fafca4;
	position: absolute;
	top: 50%;
	margin-top: -50px;
	left: 100%;
	z-index: 2;
}
.Breadcrumbs li a:before {
	content: ' ';
	display: block;
	width: 0;
	height: 0;
	border-top: 50px solid transparent; /* Go big on the size, and let overflow hide */
	border-bottom: 50px solid transparent;
	border-left: 30px solid white;
	position: absolute;
	top: 50%;
	margin-top: -50px;
	margin-left: 1px;
	left: 100%;
	z-index: 1;
}
.Breadcrumbs li:first-child a {
	padding-left: 10px;
}
.Breadcrumbs li:nth-child(2) a {
	background: #cccf19;
}
.Breadcrumbs li:nth-child(2) a:after {
	border-left-color: #cccf19;
}
.Breadcrumbs li:nth-child(3) a {
	background: #959708;
}
.Breadcrumbs li:nth-child(3) a:after {
	border-left-color: #959708;
}
.Breadcrumbs li:nth-child(4) a {
	background: #7e8008;
}
.Breadcrumbs li:nth-child(4) a:after {
	border-left-color: #7e8008;
}
.Breadcrumbs li:nth-child(5) a {
	background: #75770d;
}
.Breadcrumbs li:nth-child(5) a:after {
	border-left-color: #75770d;
}
.Breadcrumbs li:last-child a {
	pointer-events: none;
}
.Breadcrumbs li a:hover:after {
	border-left-color: #cccf19 !important;
}
.Breadcrumbs li:first-child a:hover:after {
	border-left-color: #fafca4 !important;
}
.Breadcrumbs li:first-child a {
	color: black;
}
.Breadcrumbs li:nth-child(3) a:hover:after {
	border-left-color: #959708 !important;
}
.Breadcrumbs li:nth-child(4) a:hover:after {
	border-left-color: #7e8008 !important;
}
.Breadcrumbs li:nth-child(5) a:hover:after {
	border-left-color: #75770d !important;
}
