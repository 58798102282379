.container {
	/* display: flex;
	flex-flow: column;
	align-items: flex-start; */
	font-family: var(--common-font);
	border: 2px solid #f26122;
	border-radius: 50px;
	padding: 0px 23px 5px 23px;
	margin-bottom: 15px;
	background-color: #fff;
}

/* .voidTransactionModal {
	z-index: 1;
} */

.container label {
	font: normal normal 600 12px/25px 'Poppins-Light', sans-serif;
	display: block;
}

.container h1 {
	font: normal normal 3.5vh 'Wavehaus-SemiBold', sans-serif;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.container h3 {
	font-family: var(--common-font-bold);
	font-size: 2.1vh;
	letter-spacing: 0px;
	color: #3b3b3b;
	margin-bottom: 2.2vh;
}

.voidStatusContainer {
	text-align: right;
}

.voidStatusWrap {
	text-align: left;
	display: inline-block;
}

.voidStatusWrap label {
	margin-bottom: 1.1vh;
}

.voidStatusWrap pre {
	font-family: var(--common-font-bold) !important;
}

.voidRequestInfoContainer ul {
	list-style: none;
	display: table;
}

.voidRequestInfoContainer ul li {
	display: table-row;
	line-height: 2.6vh;
}

.voidRequestInfoContainer ul > li > div {
	font: normal normal 600 12px/25px 'Poppins-Light', sans-serif;
	display: table-cell;
	padding-right: 3em;
}

.voidRequestInfoContainer ul > li > span {
	font: normal normal normal 12px/25px 'Poppins-Light', sans-serif;
	padding-left: 3em;
}

.voidRequestInfoContainer label {
	font: normal normal medium 12px/25px 'Poppins-Light', sans-serif;
	/* margin-bottom: 1.4vh; */
}

.voidRequestFields div > label {
	font: normal normal medium 12px/25px 'Poppins-Light', sans-serif;
	padding-top: 0 !important;
}

.voidRequestItem {
	display: inline-flex;
}

.voidReasonItemContainer {
	border: 1.8px solid #dddbda;
	padding: 0.8vmax;
	border-radius: 10px;
}

.voidReasonItemContainer span > label {
	margin-bottom: 2.7vh;
}

.statusPill {
	border-radius: 50px;
}
.divider {
	border-top: 0.5vh solid #0176c0;
}

.footer {
	display: flex;
	justify-content: flex-end;
	padding: 0.1vmax;
	position: sticky;
	bottom: 0;
}

.footer .btn {
	min-width: 5vw;
}

.contactDetailsErrorCloseBtn {
	min-width: 40%;
}

.pill {
	align-items: center;
	font-weight: bold;
	letter-spacing: 0.7px;
	font-size: 0.8vw;
	display: inline-block;
	height: 100%;
	white-space: nowrap;
	width: auto;

	position: relative;
	border-radius: 100px;
	overflow: hidden;
	padding: 0 1.2vw 0 1.2vw;
	text-overflow: ellipsis;
	line-height: 1.8vw;
	color: #effaee;

	word-break: break-word;
}
.pill_pending {
	background: #f26122;
}
.pill_approved {
	background: #6fcf6c;
}
.pill_rejected {
	background: #ff0000;
}
.pill_expired {
	background: #b3b7bb;
}

.responsibilityCheckbox span > label > span {
	padding-right: 50 !important;
	font-size: 1.5vh !important;
}

.incidentReportBox {
	margin-bottom: 0.5vh;
	margin-left: 0.5vw;
	margin-right: 1vw !important;
}

.topSticky {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background: #fff;
	z-index: 3;
	padding-top: 2.8vh;
	padding-bottom: 0.3vh;
}

.bottomSticky {
	position: -webkit-sticky;
	position: sticky;
	bottom: 0;
	background: #fff;
	z-index: 4;
	padding-top: 2vh;
	padding-bottom: 0.6vh;
	/* padding-left: 1.5vw;
	padding-right: 1.5vw; */
}

.addressErrorCloseBtn {
	min-width: 40%;
}

.subtext {
	color: #969698;
	font-size: 0.8vw;
}

.successHeader {
	margin-bottom: 2vh;
	font-size: 1vw;
}
.succcessMessage > div {
	font-size: 5vw;
}

.trnText {
	margin-bottom: 3.2vh;
	font-size: 0.8vw;
}

.voidHeader {
	font: normal normal 600 22px/26px 'Wavehaus-SemiBold', sans-serif;
}

.voidRefId {
	color: #0076c0;
	font: normal normal 600 14px/21px 'Poppins-Light', sans-serif;
}

.voidSubHeader {
	font: normal normal 600 14px/21px 'Poppins-Light', sans-serif;
	margin-bottom: 24px;
	display: block;
}

.vrText {
	font: normal normal 12px/18px 'Poppins-Regular', sans-serif;
	padding-right: 8px;
}

.voidStickyLabel {
	padding-right: 10px;
}
