.rowBreak {
	word-break: break-all;
}

.paperContainers {
	padding: 0.7vmax 0.3vmax 0.7vmax 0.3vmax !important;
	border-radius: 20px;
}

.actions {
	display: flex;
}

.tooltip {
	background: #f1f1f1 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 2px;
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.tooltip > div {
	color: #3b3b3b !important;
	font: normal normal 10px/16px 'Poppins-Light', sans-serif;
}

.actions > div {
	margin: 2px 5px 2px 5px;
	width: 50%;
	text-align: center;
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	overflow: hidden;
}

.actionIcon {
	font-weight: bold;
}

.actionHeader {
	text-align: center;
}

.errorBodyContent {
	padding-left: 15px;
	padding-right: 15px;
	width: 300px;
}

.errorHeader {
	margin-bottom: 20px;
	font: normal normal 18px 'Poppins-Light', sans-serif;
}

.errorBody {
	font: normal normal 12px/16px 'Poppins-Light', sans-serif;
}

.errorFooter {
	margin-top: 0;
	margin-bottom: 30px;
	font: normal normal 12px/16px 'Poppins-Light', sans-serif;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 14px !important;
}

.statusText {
	color: #14bb02;
	font-style: italic;
	font-size: 13px;
}

.statusVerifiedText {
	color: #14bb02;
	font-style: italic;
	font-size: 13px;
}

.statusUnverifiedText {
	color: #c84031;
	font-style: italic;
	font-size: 13px;
}

/*Success Modal*/
.successHeader {
	font-size: 19px;
	margin-bottom: 5px;
}

.successBody {
	font-size: 12px;
	margin-bottom: 10px;
}

.successModalBtn {
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}

.confirmModalWithRemarks > div {
	width: 500px !important;
}

.successModal {
	width: 372px !important;
	min-height: 293px !important;
	padding: 1.8vw !important;
	margin-bottom: 1px;
}
.bodyHeaderEmphasis {
	font-weight: bold;
}
