.truncated {
	padding: 0;
	margin: 0;
}

.tooltip {
	background-color: #f6a07b;
	color: #ffffff;
}

.button {
	padding: 0;
	text-align: left;
	cursor: default;
	position: relative;
}

.count {
	line-height: 20px;
	margin-top: 20px;
	font-size: var(--input-label-font-size) !important;
}

.text {
	line-height: 20px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.textContainer {
	display: flex;
}
