/* Field */
.errorText {
	font-size: var(--input-label-font-size);
	color: #ea001e;
}

.errorDateField input {
	border: 2px solid #ea001e;
	border-radius: 5px;
}

/* Expanded view */
.container {
	width: 539px;
}

.content {
	max-height: 100vh;
}

.row {
	cursor: pointer;
}
