.modal {
	width: 440px !important;
	min-width: 0 !important;
}

.footer {
	background-color: #ffffff;
	padding: 0 20px 0 0;
}

.headerText {
	padding: 25px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-family: 'wavehaus-95-semibold', sans-serif;
}

.bodyText {
	font-family: var(--common-font);
	font-size: 0.9vw;
	letter-spacing: 0;
	color: #3e3f42;
	padding: 50px;
}

.actionBtn {
	min-width: 10%;
	padding: 20px;
}
