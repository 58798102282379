.customLoader {
	width: 25px;
	height: 6px;
	background: radial-gradient(circle closest-side, #ffd6a5 90%, #0000) 0% 50%,
		radial-gradient(circle closest-side, #f3aa60 90%, #0000) 50% 50%,
		radial-gradient(circle closest-side, #f24c3d 90%, #0000) 100% 50%;
	background-size: calc(100% / 3) 100%;
	background-repeat: no-repeat;
	animation: d7 1s infinite linear;
}

@keyframes d7 {
	33% {
		background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
	}
	50% {
		background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
	}
	66% {
		background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
	}
}

.customLoaderContainter {
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.errorHeader {
	font-size: 1vmax;
	margin-bottom: 10px;
}

.errorFooter {
	margin-top: 0;
	font-size: 0.8vmax;
	margin-bottom: 30px;
}

.errorModalBtn {
	margin-bottom: 10px;
	padding: 19px 6px;
	width: 120px;
	height: 40px;
	font-size: 13px !important;
}
