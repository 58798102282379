.customModalHeader {
	font-size: 22px;
	margin-top: 32px;
	margin-left: 28px;
	margin-bottom: 20px;
}

.title {
	font-size: 22px;
	margin-top: 32px;
	margin-left: 28px;
}
