.vspaced > * {
	margin-bottom: 0.5vh;
}

.content {
	overflow-y: auto;
	max-height: 310px;
	/* block-size: fit-content; */
	padding: 0 12px 65px 12px;
	overflow-x: hidden;
	align-items: center;
	/* margin-bottom: 100px; */
}

.divider {
	border-top: 0.5vh solid #0176c0;
	margin: 0;
}

.emailTag {
	padding-top: 10px;
}

.dateTimeBorder {
	border: 1px solid #dadce0;
	border-radius: 5px;
	padding: 10px !important;
	font-family: var(--common-font);
	width: 100%;
	margin-top: 20px;
	margin-right: 20px;
}

.sectionTitle {
	padding: 10px 0 10px 0;
	font-weight: 600;
}

.multiline-textfield {
	width: 50px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	background-color: black !important;
}

/* .sectionContent {
    padding: 0;
} */

.modeOftransmission {
	margin-top: 3px;
}
