.componentContainer {
	border: 1.8px solid #dddbda;
	padding: 0.8vmax;
	border-radius: 10px;
}

.componentContainerError {
	border: 2.5px solid #ea001e;
	padding: 0.7vmax;
	border-radius: 10px;
}

.itemContainer {
	padding-top: 1.25vmax;
}

.itemContainer span > label {
	margin-bottom: 2.3vh;
}

.searchBar {
	margin-top: 0.5vmax;
	margin-left: 1.1vmax;
	margin-right: 1.1vmax;
}

.firstItemColumn {
	padding-left: 1.2vmax;
}
.secondItemColumn {
	padding-left: 3.2vmax;
}

.helper {
	font-size: var(--input-label-font-size);
}
